import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Dialog, Section } from '@radix-ui/themes';

import PageTitle from 'components/layout/PageTitle';
import { AccountApproveDialog, AccountReasonDialog } from 'components/dialog';
import TableWrapper from 'components/table/TableWrapper';

import { instance } from 'api/axios.instance';

// 회원 관리 (프로젝트 관리자, 웹 마스터 관리자 전용)
export default function Account() {
  const boardType = 'account';

  // hooks
  const [searchParams] = useSearchParams();

  // state
  const [fetchData, setFetchData] = useState({ list: [], pagination: {} }); // 회원 목록
  const [loading, setLoading] = useState(true); // 로딩 상태
  const [isOpenApproveDialog, setIsOpenApproveDialog] = useState(false); // 계정 승인 모달
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false); // 계정 정지 사유 모달
  const [userId, setUserId] = useState(null); // 계정 관리할 회원 아이디
  const [userStatus, setUserStatus] = useState(null); // 계정 관리할 회원 상태

  // 회원 상태 변경 API
  const handleUpdateIsUsed = async (status, reason) => {
    const response = await instance('/admin/account/edit', {
      method: 'POST',
      data: {
        id: userId,
        status,
        reason
      }
    });

    if (response.data.status === 201) {
      await fetchList();
      status === 'reject' || status === 'unused' ? setIsRejectDialogOpen(false) : setIsOpenApproveDialog(false);
    }
  };

  // 계정 승인 모달
  const openDialog = (id, status) => {
    setIsOpenApproveDialog(true);
    setUserStatus(status);
    setUserId(id);
  };

  // 테이블 헤더 (테이블 내 형식 및 이벤트 관리)
  const tableHeader = [
    { label: '번호', key: 'index', width: '70px' },
    { label: '지역', key: 'region', width: '70px' },
    { label: '아이디', key: 'id', width: '120px', left: true },
    {
      label: '이메일',
      key: 'email',
      width: '260px',
      left: true
    },
    { label: '생성일', key: 'createDate', width: '120px' },
    { label: '회원분류', key: 'userType', width: '160px' },
    {
      label: '계정관리',
      key: 'status',
      width: '160px',
      code: 'id',
      option: {
        label: {
          pending: '승인하기',
          approve: '정지하기',
          reject: '미사용',
          unused: '미사용'
        },
        value: {
          pending: {
            clickEvent: id => openDialog(id, 'pending')
          },
          approve: {
            clickEvent: id => openDialog(id, 'approve')
          },
          reject: {
            disabled: true
          },
          unused: {
            disabled: true
          }
        }
      }
    }
  ];

  // 회원 목록 조회 API
  const fetchList = useCallback(async () => {
    setLoading(true);
    try {
      const searchTypeMap = {
        지역: 'region',
        아이디: 'id'
      };

      const response = await instance('/admin/account/list', {
        method: 'POST',
        data: {
          pageNo: Number(searchParams.get('page')) || 1, // 페이지 번호
          searchType: searchTypeMap[searchParams.get('searchType')] || '', // 검색 카테고리
          search: searchParams.get('search') || '' // 검색어
        }
      });
      setFetchData({ pagination: response.data.data.pagination, list: response.data.data.accounts });
    } finally {
      setLoading(false);
    }
  }, [searchParams]);

  // mount 및 searchParams 변경 시 호출
  useEffect(() => {
    fetchList();
  }, [fetchList, searchParams]);

  return (
    <>
      <Section>
        <PageTitle title="회원 관리" />
        <TableWrapper tableData={{ tableHeader, tableBody: fetchData }} boardType={boardType} loading={loading} fetchList={fetchList} />
      </Section>

      {isOpenApproveDialog && (
        <Dialog.Root open={isOpenApproveDialog} onOpenChange={setIsOpenApproveDialog}>
          <AccountApproveDialog
            userStatus={userStatus}
            onApproveClick={handleUpdateIsUsed}
            onRejectClick={() => {
              setIsOpenApproveDialog(false);
              setIsRejectDialogOpen(true);
            }}
          />
        </Dialog.Root>
      )}
      {isRejectDialogOpen && (
        <Dialog.Root open={isRejectDialogOpen} onOpenChange={setIsRejectDialogOpen}>
          <AccountReasonDialog userStatus={userStatus} onClick={handleUpdateIsUsed} />
        </Dialog.Root>
      )}
    </>
  );
}
