import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Section, Box, Flex, TextField, Text, Button, Table, RadioGroup, Separator } from '@radix-ui/themes';
import { useFormik } from 'formik';

import PageTitle from 'components/layout/PageTitle';
import { Textarea } from 'components/form';

import { instance } from 'api/axios.instance';
import useRenderComponent from 'hooks/useRenderComponent';

// 지역 이슈 관리 > 신규 등록 목록 > 상세
export default function ProjectRequestDetail() {
  // hooks
  const navigate = useNavigate();
  const { id } = useParams();
  const { Components, handleRenderComponents } = useRenderComponent(); // 동적 컴포넌트 hook

  // state
  const [formValues, setFormValues] = useState({
    title: '', // 프로젝트 명
    alias: '', // 설명
    source: '', // 출처,
    thumbnail: null, // 썸네일
    agendaData: [] // 아젠다
  });
  const [alias, setAlias] = useState('1'); // 별칭 아젠다 (지역 현안, 아이디어, 프로젝트)

  // form 유효성 검사
  const formik = useFormik({
    initialValues: formValues,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true
  });

  // 프로젝트 상세 조회 API
  const fetchAgendaById = useCallback(async () => {
    const response = await instance(`/project/${id}`);

    if (response.data.status === 200) {
      handleRenderComponents(response.data.data.data.map(data => data.componentName));
      setAlias(response.data.data.stage.split('단계')[0]);
      setFormValues({
        title: response.data.data.title,
        description: response.data.data.description || '',
        source: response.data.data.source || '',
        alias: response.data.data.alias,
        stage: `${response.data.data.stage.split('단계')[0]}단계`,
        thumbnail: response.data.data.thumbnail?.fileUrl,
        agendaData: response.data.data.data.map(data => {
          return {
            templateCode: data.templateCode,
            templateData: data.templateData
          };
        })
      });
    }
  }, [handleRenderComponents, id]);

  // mount 시 호출
  useEffect(() => {
    fetchAgendaById();
  }, [fetchAgendaById]);

  return (
    <Section className="template-form-wrap">
      <PageTitle title="지역 이슈 프로젝트 상세" />

      <Box className="box box-white">
        <Box mb="var(--space-10)">
          <Table.Root className="table-write">
            <Table.Body>
              <Table.Row align="center">
                <Table.ColumnHeaderCell colSpan={2}>
                  <Text className="required-start">프로젝트 명</Text>
                </Table.ColumnHeaderCell>
                <Table.Cell colSpan={10}>
                  <Text as="label" htmlFor="title">
                    <TextField.Root
                      readOnly
                      size="2"
                      id="title"
                      required
                      placeholder="강원도 고령 인구로 인한 버스노선 증축"
                      value={formik.values.title}
                    />
                  </Text>
                </Table.Cell>
              </Table.Row>
              <Table.Row align="center">
                <Table.ColumnHeaderCell colSpan={2}>
                  <Text className="required-start">설명</Text>
                </Table.ColumnHeaderCell>
                <Table.Cell colSpan={10}>
                  <Text as="label" htmlFor="description">
                    <Textarea
                      readOnly
                      size="2"
                      id="description"
                      required
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Text>
                </Table.Cell>
              </Table.Row>
              <Table.Row align="center">
                <Table.ColumnHeaderCell colSpan={2}>
                  <Text className="required-start">출처</Text>
                </Table.ColumnHeaderCell>
                <Table.Cell colSpan={10}>
                  <Text as="label" htmlFor="source">
                    <TextField.Root
                      readOnly
                      size="2"
                      id="source"
                      required
                      defaultValue={formik.values.source}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Text>
                </Table.Cell>
              </Table.Row>
              <Table.Row align="center">
                <Table.ColumnHeaderCell colSpan={2}>
                  <Text className="required-start">썸네일 첨부</Text>
                </Table.ColumnHeaderCell>
                <Table.Cell colSpan={10}>
                  <Flex direction="column" align="start" px="var(--space-1)">
                    {formik.values.thumbnail && (
                      <Box mt="2">
                        <img src={formik.values.thumbnail} alt="썸네일 미리보기" style={{ width: '150px', height: '212px' }} />
                      </Box>
                    )}
                  </Flex>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table.Root>
        </Box>

        <Box mb="var(--space-10)">
          <RadioGroup.Root size="3" color="blue" value={alias}>
            <Flex align="center" gap="var(--space-7)">
              <Text as="label" className="sub-title1">
                <Flex align="center">
                  <RadioGroup.Item disabled value="1" /> 지역현안
                </Flex>
              </Text>
              <Text as="label" className="sub-title1">
                <Flex align="center">
                  <RadioGroup.Item disabled value="2" /> 아이디어
                </Flex>
              </Text>
              <Text as="label" className="sub-title1">
                <Flex align="center">
                  <RadioGroup.Item disabled value="3" /> 프로젝트
                </Flex>
              </Text>
            </Flex>
          </RadioGroup.Root>
        </Box>

        <Separator size="4" mb="var(--space-5)" />

        {/* 아젠다 */}
        {Components.length > 0 && (
          <Suspense fallback={<div />}>
            {Components.map((Component, index) => (
              <Component key={index} index={index} formik={formik} setFormValues={setFormValues} readOnly />
            ))}
          </Suspense>
        )}
      </Box>

      <Box>
        <Flex gap="var(--space-2)" justify="end">
          <Button size="2" type="button" variant="outline" onClick={() => navigate('/projectRequest')}>
            취소
          </Button>
        </Flex>
      </Box>
    </Section>
  );
}
