import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dialog, Section } from '@radix-ui/themes';
import { useNavigate, useSearchParams } from 'react-router-dom';

import PageTitle from 'components/layout/PageTitle';
import { StateChangeDialog, ConfirmDialog } from 'components/dialog';
import TableWrapper from 'components/table/TableWrapper';

import { selectType } from 'store/slices/users';
import { instance } from 'api/axios.instance';

// 지역 이슈 관리 > 신규 등록 목록 > 목록
export default function ProjectRequest() {
  const boardType = 'projectRequest';

  // hooks
  const type = useSelector(selectType); // 사용자 회원 분류
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // state
  const [fetchData, setFetchData] = useState({ list: [], pagination: {} }); // 신규 등록한 프로젝트 목록
  const [loading, setLoading] = useState(true); // 로딩 상태
  const [projectId, setProjectId] = useState(''); // 상태 변경 할 프로젝트 아이디
  const [reason, setReason] = useState(''); // 수정 요청 사유
  const [isOpenStateDialog, setIsOpenStateDialog] = useState(false); // 승인/수정 요청 모달
  const [isOpenRejectDialog, setIsOpenRejectDialog] = useState(false); // 수정 사유 모달

  // 테이블 헤더 (테이블 내 형식 및 이벤트 관리)
  const tableHeader = [
    { label: '번호', key: 'index', width: '64px' },
    {
      label: '프로젝트 명',
      key: 'title',
      width: 'auto',
      left: true,
      className: 'has-detail',
      code: 'id'
    },
    { label: '프로젝트 단계', key: 'stage', width: '160px' },
    {
      label: '상태',
      key: 'status',
      width: '160px',
      code: 'id',
      code2: 'reason',
      option: {
        label: {
          pending: type === 'user' ? '등록 신청 완료' : '승인하기',
          approve: '등록 완료',
          reject: type === 'user' ? '수정하기' : '수정 요청 완료'
        },
        value: {
          pending: {
            clickEvent: id => {
              setProjectId(id);
              setIsOpenStateDialog(true);
            },
            disabled: type === 'user'
          },
          approve: {
            disabled: true
          },
          reject: {
            clickEvent: (id, reason) => {
              setProjectId(id);
              setReason(reason);
              setIsOpenRejectDialog(true);
            },
            disabled: type !== 'user'
          }
        }
      }
    },
    { label: '등록일', key: 'createDate', width: '120px' }
  ];

  // 신규 등록 목록 조회 API
  const fetchList = useCallback(async () => {
    const searchTypeMap = {
      제목: 'title'
    };

    setLoading(true);

    try {
      const response = await instance('/project/list', {
        method: 'POST',
        data: {
          pageNo: Number(searchParams.get('page')) || 1, // 페이지 번호
          searchType: searchTypeMap[searchParams.get('searchType')] || '', // 검색 카테고리
          search: searchParams.get('search') || '', // 검색어
          region: searchParams.get('region') || '' // 지역
        }
      });
      setFetchData({ pagination: response.data.data.pagination, list: response.data.data.projects });
    } finally {
      setLoading(false);
    }
  }, [searchParams]);

  // 프로젝트 상태 변경 API
  const handleUpdateStatus = async (status, content) => {
    setIsOpenStateDialog(false);
    try {
      const response = await instance('/admin/project/examine', {
        method: 'POST',
        data: {
          id: projectId,
          status,
          reason: content
        }
      });
      if (response.data.status === 201) {
        fetchList();
      }
    } catch (error) {}
  };

  // mount 및 searchParams 변경 시 호출
  useEffect(() => {
    fetchList();
  }, [fetchList, searchParams]);

  return (
    <>
      <Section>
        <PageTitle title="신규 등록 목록" />
        <TableWrapper tableData={{ tableHeader, tableBody: fetchData }} boardType={boardType} loading={loading} fetchList={fetchList} />
      </Section>

      {isOpenStateDialog && (
        <Dialog.Root open={isOpenStateDialog} onOpenChange={setIsOpenStateDialog}>
          <StateChangeDialog
            requestState="등록 신청"
            leftOption={['등록 신청']}
            rightOption={['등록 완료', '수정 요청']}
            handleClick={handleUpdateStatus}
          />
        </Dialog.Root>
      )}
      {isOpenRejectDialog && (
        <Dialog.Root open={isOpenRejectDialog} onOpenChange={setIsOpenRejectDialog}>
          <ConfirmDialog
            title="수정 요청 사유"
            description={reason}
            onConfirm={() => navigate(`/projectRequest/write/${projectId}`)}
            showTitle
            showCancel={false}
            buttonName="수정 페이지 이동"
          />
        </Dialog.Root>
      )}
    </>
  );
}
