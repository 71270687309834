import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Section } from '@radix-ui/themes';

import PageTitle from 'components/layout/PageTitle';
import TableWrapper from 'components/table/TableWrapper';

import { instance } from 'api/axios.instance';

// 게시판 관리 > 홍보자료 관리 (웹 마스터 관리자 전용)
export default function Promotion() {
  const boardType = 'promotion';

  // hooks
  const [searchParams] = useSearchParams();

  // state
  const [fetchData, setFetchData] = useState({ list: [], pagination: {} }); // 홍보자료 목록
  const [loading, setLoading] = useState(true); // 로딩 상태

  // 테이블 헤더 (테이블 내 형식 및 이벤트 관리)
  const tableHeader = [
    { label: '선택', key: 'checkbox', width: '64px' },
    { label: '번호', key: 'index', width: '64px' },
    { label: '제목', key: 'title', width: 'auto', left: true, className: 'has-detail', code: 'postNo' },
    { label: '첨부파일', key: 'isFile', width: '108px' },
    { label: '조회수', key: 'viewCount', width: '120px' },
    { label: '등록일', key: 'createDate', width: '220px' }
  ];

  // 홍보자료 목록 조회 API
  const fetchList = useCallback(async () => {
    const searchTypeMap = {
      제목: 'title',
      내용: 'content'
    };

    setLoading(true);
    try {
      const response = await instance('/post/list', {
        method: 'POST',
        data: {
          boardType, // 게시판 타입
          pageNo: Number(searchParams.get('page')) || 1, // 페이지 번호
          searchType: searchTypeMap[searchParams.get('searchType')] || '', // 검색 카테고리
          search: searchParams.get('search') || '', // 검색어
          pageSize: 10, // 페이지 별 노출 개수
          orderBy: 'post_no', // 정렬 기준
          inOrder: 'desc' // 정렬 순서 (오름차순 | 내림차순)
        }
      });
      setFetchData({ pagination: response.data.data.pagination, list: response.data.data.posts });
    } finally {
      setLoading(false);
    }
  }, [searchParams]);

  // mount 및 searchParams 변경 시 호출
  useEffect(() => {
    fetchList();
  }, [fetchList, searchParams]);

  return (
    <Section>
      <PageTitle title="홍보자료 관리" />
      <TableWrapper tableData={{ tableHeader, tableBody: fetchData }} boardType={boardType} loading={loading} fetchList={fetchList} />
    </Section>
  );
}
