import { useCallback, useEffect, useState } from 'react';
import { Section } from '@radix-ui/themes';
import { useSearchParams } from 'react-router-dom';

import PageTitle from 'components/layout/PageTitle';
import TableWrapper from 'components/table/TableWrapper';

import { instance } from 'api/axios.instance';

export default function Question() {
  const boardType = 'question';

  // hooks
  const [searchParams] = useSearchParams();

  // state
  const [loading, setLoading] = useState(true);
  const [fetchData, setFetchData] = useState({ list: [], pagination: {} }); // 문의하기 목록

  const tableHeader = [
    { label: '번호', key: 'index', width: '64px' },
    { label: '제목', key: 'title', width: 'auto', left: true, className: 'has-detail', code: 'postNo' },
    { label: '지역', key: 'region', width: '70px' },
    { label: '등록일', key: 'createDate', width: '220px' },
    {
      label: '운영상태',
      key: 'commentStatus',
      width: '160px'
    }
  ];
  // 문의하기 리스트 조회
  const fetchList = useCallback(async () => {
    const searchTypeMap = {
      제목: 'title',
      내용: 'content'
    };

    setLoading(true);
    try {
      const response = await instance('/post/list', {
        method: 'POST',
        data: {
          boardType, // 게시판 타입
          pageNo: Number(searchParams.get('page')) || 1, // 페이지 번호
          searchType: searchTypeMap[searchParams.get('searchType')] || '', // 검색 카테고리
          search: searchParams.get('search') || '', // 검색어
          pageSize: 10, // 페이지 별 노출 개수
          orderBy: 'post_no', // 정렬 기준
          inOrder: 'desc' // 정렬 순서 (오름차순 | 내림차순)
        }
      });

      const posts = response.data.data.posts.map(post => ({
        ...post,
        commentStatus: (
          <span
            style={{
              color: post.commentStatus === 'completed' ? '#4A8AE4' : ''
            }}
          >
            {post.commentStatus === 'pending'
              ? '문의중'
              : post.commentStatus === 'processing'
                ? '접수완료'
                : post.commentStatus === 'completed'
                  ? '답변완료'
                  : '상태 없음'}
          </span>
        )
      }));

      setFetchData({ pagination: response.data.data.pagination, list: posts });
    } finally {
      setLoading(false);
    }
  }, [searchParams]);

  // mount 및 searchParams 변경 시 호출
  useEffect(() => {
    fetchList();
  }, [fetchList, searchParams]);

  return (
    <Section>
      <PageTitle title="문의하기" />
      <TableWrapper tableData={{ tableHeader, tableBody: fetchData }} boardType={boardType} loading={loading} fetchList={fetchList} />
    </Section>
  );
}
