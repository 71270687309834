import axios from 'axios';

const baseURL = process.env.REACT_APP_API_BASE;

export const defaultHeaders = {
  'Content-Type': 'application/json'
  // 'Cache-Control': 'no-cache' // 자체 캐쉬 처리되어 실제 서버를 호출하지 않는 현상 해결
};

export const createCustomInstance = (customURL, config = defaultHeaders) => {
  return axios.create({
    baseURL: customURL,
    headers: config,
    timeout: 5000, // 시간이 오래 걸리는 요청에 대한 timeout 값 재정의, 5초
    withCredentials: true
  });
};

// API instance
export const instance = createCustomInstance(baseURL);

/**
 * API 호출 전 공통 로직
 */
instance.interceptors.request.use(
  config => {
    console.log('config : ', config);
    console.debug(`request => ${config.url}`, config);
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);
