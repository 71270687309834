import React, { useCallback, useEffect, useState } from 'react';
import { Section } from '@radix-ui/themes';

import PageTitle from 'components/layout/PageTitle';
import TableWrapper from 'components/table/TableWrapper';

import { instance } from 'api/axios.instance';

// 실태 조사 관리
export default function RealSurvey() {
  const boardType = 'realSurvey';

  // state
  const [fetchData, setFetchData] = useState({ list: [], pagination: {} }); // 실태 조사 목록
  const [loading, setLoading] = useState(false); // 로딩 상태

  // 테이블 헤더 (테이블 내 형식 및 이벤트 관리)
  const tableHeader = [
    { label: '선택', key: 'checkbox', width: '64px' },
    { label: '번호', key: 'postNo', width: '64px' },
    { label: '년도', key: 'year', width: '120px' },
    { label: '지역', key: 'region', width: '120px' },
    {
      label: '제목',
      key: 'title',
      width: 'auto',
      left: true
    },
    { label: '등록일', key: 'createDate', width: '120px' }
  ];

  // 실태 조사 목록 조회 API
  const fetchList = useCallback(async () => {
    setLoading(true);
    try {
      const response = await instance('/statistic/2021/ALL', {
        method: 'GET'
      });

      setFetchData({ pagination: response.data.data.pagination, list: response.data.data.list });
    } finally {
      setLoading(false);
    }
  }, []);

  // mount 시 호출
  useEffect(() => {
    fetchList();
  }, [fetchList]);

  return (
    <Section>
      <PageTitle title="실태조사 목록" />
      <TableWrapper tableData={{ tableHeader, tableBody: fetchData }} boardType={boardType} loading={loading} fetchList={fetchList} />
    </Section>
  );
}
