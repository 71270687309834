import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Flex, Box } from '@radix-ui/themes';
import { useState } from 'react';
import { Dialog } from '@radix-ui/themes';
import { PasswordChangeDialog } from 'components/dialog';
import { userInfo, logoutUser } from 'store/slices/users';
import './header.scss';

// 레이아웃 헤더
export default function Header() {
  // hooks
  const dispatch = useDispatch();
  const userData = useSelector(userInfo);
  const { userId, type } = userData;
  const [isOpenChangePasswordDialog, setIsOpenChangePasswordDialog] = useState(false); // 템플릿 미리 보기 모달

  // 팝업 노출 Method
  const handlePopup = async () => {
    // await dispatch(logoutUser());
    // console.log('팝업 열어주세요');
    setIsOpenChangePasswordDialog(true);
  };
  // 로그아웃 Method
  const handleLogout = async () => {
    await dispatch(logoutUser());
  };

  return (
    <>
      <header>
        <Container size="4">
          <Flex align="center" className="container">
            <h1>
              <a href="/">
                <img src={require('assets/images/common/logo.svg').default} alt="SW미래채움" />
              </a>
            </h1>
            <nav>
              <Box className="user-info">
                <i className={`icon avatar ${type}`} />
                <span>{userId}님</span>
              </Box>
              {type === 'user' && (
                <button type="button" className="user-info" onClick={handlePopup}>
                  <i className={`icon change-password`} />
                  <span>비밀번호 변경</span>
                </button>
              )}
              <button className="user-info" onClick={handleLogout}>
                <i className={`icon logout`} />
                <span>로그아웃</span>
              </button>
            </nav>
          </Flex>
        </Container>
      </header>
      {isOpenChangePasswordDialog && (
        <Dialog.Root open={isOpenChangePasswordDialog} onOpenChange={setIsOpenChangePasswordDialog}>
          <PasswordChangeDialog setIsOpenChangePasswordDialog={setIsOpenChangePasswordDialog} />
        </Dialog.Root>
      )}
    </>
  );
}
