import { useState, createContext } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Flex, Table, Text } from '@radix-ui/themes';

import TableBody from 'components/table//TableBody';
import TableAction from 'components/table//TableAction';
import Pagination from 'components/table/Pagination';
import TableSearchBox from 'components/table//TableSearchBox';
import TableHeader from 'components/table/TableHeader';
import 'components/table//tableWrapper.scss';
import LocalSort from 'components/table/SortByLocal';

import { selectType } from 'store/slices/users';

// 테이블 데이터 컨텍스트
export const TableContext = createContext(null);

// 테이블 공통 컴포넌트
export default function TableWrapper({ tableData, boardType, loading, fetchList }) {
  // hooks
  const location = useLocation();
  const navigate = useNavigate();
  const type = useSelector(selectType);
  const [searchParams, setSearchParams] = useSearchParams();

  // state
  const [selectedPosts, setSelectedPosts] = useState(''); // 선택한 데이터

  // API 재호출 Method
  const refetch = () => {
    fetchList();
    setSelectedPosts('');
  };

  // 상세 페이지 이동 Method
  const onClickCell = code => navigate(`${location.pathname}/detail/${code}`);

  // 검색 Method
  const getSearchText = ({ type, text }) => {
    searchParams.set('page', 1);
    searchParams.set('searchType', type);
    searchParams.set('search', text);
    setSearchParams(searchParams);
  };

  // 지역 선택 Method
  const setRegion = region => {
    searchParams.set('page', 1);
    searchParams.set('searchType', '');
    searchParams.set('search', '');
    searchParams.set('region', region);
    setSearchParams(searchParams);
  };

  // 페이지 이동 Method
  const handlePageChange = pageNo => {
    const totalPages = tableData.tableBody?.pagination.totalPage;

    if (pageNo >= 1 && pageNo <= totalPages) {
      searchParams.set('page', pageNo);
      setSearchParams(searchParams);
    }
  };

  // 테이블 데이터 컨텍스트
  const tableContextValue = {
    tableHeader: tableData.tableHeader,
    tableBody: tableData.tableBody,
    boardType,
    selectedPosts,
    setSelectedPosts,
    setRegion,
    onClickCell,
    getSearchText,
    refetch
  };

  return (
    <TableContext.Provider value={tableContextValue}>
      <Box className="box box-white">
        {['projectRequest', 'agendaRequest', 'project'].includes(boardType) && type !== 'user' && <LocalSort />}

        <Flex justify="between" align="center">
          <Flex align="center">
            <Text as="p" color="gray">
              Total&nbsp;
              <Text color="sky">{tableData.tableBody?.pagination?.totalCount?.toLocaleString() || '0'}</Text>
            </Text>
          </Flex>
          <Flex justify="end" align="center" gap="2" className="board-search-box">
            <TableSearchBox />
          </Flex>
        </Flex>

        {tableData?.tableBody?.pagination?.totalCount > 0 ? (
          <>
            <Box className="board-text-items" mt="var(--space-2)">
              <Table.Root variant="surface" size="3">
                <TableHeader />
                <TableBody />
              </Table.Root>
            </Box>
            <Flex justify="center" mt="6">
              <Pagination totalPage={tableData?.tableBody?.pagination.totalPage} handlePageChange={handlePageChange} />
            </Flex>
          </>
        ) : (
          !loading && (
            <Box mt="var(--space-2)">
              <Flex direction="column" align="center" justify="center" gap="var(--space-5)" className="no-data">
                <img src={require('assets/images/icon/icon-nodata.svg').default} alt="데이터에러" />
                <Text as="p" color="gray" className="body1">
                  데이터가 없습니다.
                </Text>
              </Flex>
            </Box>
          )
        )}
      </Box>
      <TableAction />
    </TableContext.Provider>
  );
}
