import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
import { Box, Text, Section, Flex, Button, TextField, Dialog } from '@radix-ui/themes';

import { ConfirmDialog } from 'components/dialog';

import { findUserId, findUserPassword } from 'store/slices/users';
import { validationEmail } from 'util/validationSchemas';
import './style.scss';

// 아이디/비밀번호 찾기
export default function FindAccount() {
  // state
  const [serverError, setServerError] = useState('');
  const [isOpenCompleteDialog, setIsOpenCompleteDialog] = useState(false); // 완료 모달

  const location = useLocation();

  // URL path에 따라 비밀번호 찾기 또는 아이디 찾기 구분
  const isPasswordFind = location.pathname === '/findPassword';
  const titleText = isPasswordFind ? '비밀번호 찾기' : '아이디 찾기';
  const submitFunction = isPasswordFind ? findUserPassword : findUserId;

  // form 유효성 검사
  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: validationEmail,
    onSubmit: async e => {
      const result = await submitFunction(e.email);
      if (result.status === 200) {
        setServerError('');
        setIsOpenCompleteDialog(true);
      } else if (result.status === 409) {
        setServerError('등록되지 않은 이메일 주소입니다.');
      }
    }
  });
  const isError = formik.touched.email && formik.errors.email; // 에러 상태 확인

  // form change Method
  const handleChange = e => {
    formik.handleChange(e);
    if (serverError) {
      setServerError('');
    }
  };

  return (
    <Section className="password-wrap">
      <form onSubmit={formik.handleSubmit}>
        <Flex direction="column" gap="var(--space-5)" py="var(--space-20)">
          <Flex align="center" justify="start" gap="var(--space-3)">
            <Box className="logo">
              <img src={require('assets/images/common/logo.svg').default} alt="NipaDreams" />
            </Box>

            <Text as="p" className="title3">
              {titleText}
            </Text>
          </Flex>

          <Text as="p" py="var(--space-5)" className="body2">
            이메일을 입력하신 후 인증 메일 받기를 클릭해 주세요.😀
          </Text>

          <Box className="email-wrap">
            <Flex
              pt="var(--space-10)"
              pb={isError || serverError ? 'var(--space-15)' : 'var(--space-10)'}
              align="center"
              gap="var(--space-2)"
            >
              <Text as="p" className="sub-title1 label">
                이메일
              </Text>

              <TextField.Root
                placeholder={isError ? '' : 'Please input your ID!'}
                className={isError ? 'error-input' : ''}
                onChange={handleChange}
                onBlur={formik.handleBlur}
                name="email"
                value={formik.values.email}
              />
              {/* 에러 메세지 : 유효하지 않은 이메일 입력 시 표시 */}
              {(isError || serverError) && (
                <Flex align="start" gap="var(--space-1)" className="state-msg">
                  <Box>
                    <img src={require('assets/images/icon/icon-error.svg').default} alt="에러아이콘" />
                  </Box>
                  <Text as="span" className="body3 font-error">
                    {isError ? formik.errors.email : serverError}
                  </Text>
                </Flex>
              )}
            </Flex>
          </Box>

          <Flex justify="end">
            <Button className="btn-xlg" type="submit">
              인증메일받기
            </Button>
          </Flex>
          {isOpenCompleteDialog && (
            <Dialog.Root open={isOpenCompleteDialog} onOpenChange={setIsOpenCompleteDialog}>
              <ConfirmDialog
                title={isPasswordFind ? '비밀번호' : '아이디'}
                description={`등록된 이메일 주소로 ${isPasswordFind ? '새로운 비밀번호' : '아이디 정보'}가 전송되었습니다.`}
                showTitle
                showCancel={false}
              />
            </Dialog.Root>
          )}
        </Flex>
      </form>
    </Section>
  );
}
