import React, { Suspense, useEffect, useState } from 'react';
import { Button, Flex, Text, Dialog, IconButton, Separator, Box } from '@radix-ui/themes';
import { Cross1Icon } from '@radix-ui/react-icons';

import './style.scss';

// 템플릿 미리보기 모달
export function TemplatePreviewDialog({ componentName }) {
  const [Component, setComponent] = useState(null);

  useEffect(() => {
    if (componentName) {
      const loadComponent = async () => {
        const { default: LoadedComponent } = await import(`components/template/${componentName}`);
        setComponent(() => LoadedComponent);
      };

      loadComponent().catch(console.error);
    }
  }, [componentName]);

  return (
    <Dialog.Content maxWidth="800px" maxHeight="800px" className="state-dialog">
      <Dialog.Title size="4">
        <Flex align="center" justify="between">
          <Text as="p" className="sub-title1">
            미리보기
          </Text>
          <Dialog.Close>
            <IconButton className="rt-variant-text close-btn">
              <Cross1Icon width={20} height={20} />
            </IconButton>
          </Dialog.Close>
        </Flex>
      </Dialog.Title>

      <Separator size="4" mt="var(--space-5)" mb="var(--space-8)" />

      <Box className="preview-contents">
        {Component && (
          <Suspense fallback={<div />}>
            <Component readOnly />
          </Suspense>
        )}
      </Box>

      <Dialog.Description />

      <Flex mt="var(--space-8)" align="center" justify="center">
        <Dialog.Close>
          <Button size="2">확인</Button>
        </Dialog.Close>
      </Flex>
    </Dialog.Content>
  );
}
