import React, { useState } from 'react';
import { Text, TextField } from '@radix-ui/themes';
import classNames from 'classnames';
import ErrorIcon from 'assets/images/icon/icon-error.svg';

export function TextInput(props) {
  const [value, setValue] = useState('');

  const handleChange = e => {
    setValue(e.target.value);
    props.handleId(e.target.value);
  };
  const handleReset = () => {
    setValue(''); // 입력 필드 초기화
  };

  return (
    <fieldset className={classNames({ 'flag-error': props.error })}>
      <TextField.Root
        size="3"
        id={props.id}
        value={value}
        onChange={handleChange}
        placeholder={props.placeholder}
        autoComplete={props.autoComplete}
        disabled={props.disabled}
      >
        {value && ( // 입력값이 있을 때만 리셋 버튼 표시
          <TextField.Slot side="right">
            <button type="button" onClick={handleReset}>
              리셋
            </button>
          </TextField.Slot>
        )}
        {props.error && ( // 에러일 때만 아이콘 표시
          <TextField.Slot side="right">
            <i className="icon-error">
              <img src={ErrorIcon} alt="에러아이콘" />
            </i>
          </TextField.Slot>
        )}
      </TextField.Root>
      {props.error && (
        <Text as="p" className="caption">
          잘못된 정보를 입력하셨습니다.
        </Text>
      )}
    </fieldset>
  );
}
