import React from 'react';
import { Section } from '@radix-ui/themes';

import PageTitle from 'components/layout/PageTitle';
import BoardWrite from 'components/board/BoardWrite';

// 게시판 관리 >  문의하기 > 등록 (웹 마스터 관리자 전용)
export default function QuestionWrite() {
  const boardType = 'question';

  return (
    <Section>
      <PageTitle title="문의하기" />
      <BoardWrite boardType={boardType} />
    </Section>
  );
}
