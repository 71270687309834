import React from 'react';
import { Box, Flex, Text, IconButton } from '@radix-ui/themes';
import PdfIcon from 'assets/images/icon/icon-pdf.svg';
import XlsIcon from 'assets/images/icon/icon-xls.svg';
import PngIcon from 'assets/images/icon/icon-png.svg';
import { DownloadIcon } from '@radix-ui/react-icons';

export function Attachment({ data }) {
  const handleDownload = async url => {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Network response was not ok');

      const blob = await response.blob(); // Blob 객체 생성
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob); // Blob URL 생성
      link.setAttribute('download', data[0].originalName); // filename을 지정하지 않으면 기본 filename 사용
      document.body.appendChild(link);
      link.click(); // 다운로드 실행
      document.body.removeChild(link); // 링크 제거
      window.URL.revokeObjectURL(link.href); // 메모리 해제
    } catch (error) {
      console.error('Download error:', error);
    }
  };

  return (
    <Flex direction="column" gap="var(--space-2)" flexGrow="1" flexShrink="1" className="post-link">
      {data?.map((el, i) => (
        <Box key={i} className="post-outline">
          <Flex align="center" gap="var(--space-3)">
            <IconButton size="1" className="rt-variant-text">
              {el?.extension === 'pdf' ? (
                <img src={PdfIcon} alt="pdf아이콘" />
              ) : el?.extension === 'xls' ? (
                <img src={XlsIcon} alt="Xls아이콘" />
              ) : (
                <img src={PngIcon} alt="png아이콘" />
              )}
            </IconButton>
            <Text as="p" color="gray" className="body2">
              {el?.originalName}
            </Text>
            <IconButton size="1" className="rt-variant-text" ml="auto" onClick={() => handleDownload(el?.fileUrl, el?.originalName)}>
              <DownloadIcon width={24} height={24} />
            </IconButton>
          </Flex>
        </Box>
      ))}
    </Flex>
  );
}
