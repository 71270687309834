import React, { useContext } from 'react';
import { Table, Button, IconButton, Flex, Checkbox } from '@radix-ui/themes';
import { EyeOpenIcon, FileIcon } from '@radix-ui/react-icons';

import { TableContext } from 'components/table/TableWrapper';
import { formatCommas } from 'util/validation';
import { useSearchParams } from 'react-router-dom';

// 테이블 바디 (테이블 데이터 영역)
export default function TableBody() {
  const [searchParams] = useSearchParams();
  const currentPage = Number(searchParams.get('page')) || 1;
  const { tableHeader, tableBody, selectedPosts, setSelectedPosts, onClickCell } = useContext(TableContext);

  return (
    <Table.Body>
      {tableBody?.list?.map((post, rowIndex) => (
        <Table.Row key={rowIndex} align="center">
          {tableHeader?.map((header, colIndex) => (
            <Table.Cell
              key={colIndex}
              justify={header.left ? 'start' : 'center'}
              data-title={header.label}
              onClick={() => (header.className === 'has-detail' ? onClickCell(post[header.code]) : null)}
              className={post[header.key] === '답변완료' ? 'font-info' : header.className}
            >
              {/* 번호 처리 */}
              {header.key === 'index' && Math.max(tableBody?.pagination?.totalCount - ((currentPage - 1) * 10 + rowIndex), 1)}
              {/* 체크박스 처리 */}
              {header.key === 'checkbox' && (
                <Flex justify="center" align="center">
                  <Checkbox
                    checked={rowIndex === selectedPosts}
                    onCheckedChange={checked => {
                      if (checked) {
                        setSelectedPosts(rowIndex);
                      } else {
                        setSelectedPosts('');
                      }
                    }}
                  />
                </Flex>
              )}
              {/* 미리보기 아이콘 처리 */}
              {header.key === 'preview' && (
                <IconButton onClick={() => header.clickEvent(post)} size="1" className="rt-variant-text" aria-label="미리보기">
                  <EyeOpenIcon width={24} height={24} />
                </IconButton>
              )}
              {/* 파일 아이콘 처리 */}
              {header.key === 'isFile' && post[header.key] === true && <FileIcon width={24} height={24} />}
              {/* 상태값 */}
              {['isUsed', 'status'].includes(header.key) && (
                <Button
                  disabled={header.option.value[post[header.key]]?.disabled}
                  onClick={() => header.option.value[post[header.key]].clickEvent(post[header.code], post[header.code2] || null)}
                  className={`rt-reset rt-BaseButton rt-r-size-1 rt-variant-outline rt-Button fluid ${
                    header.option.class?.[post[header.key]]
                  }`}
                >
                  {header.option?.label[post[header.key]]}
                </Button>
              )}

              {/* 사용 기간 */}
              {header.key === 'period' && (
                <>
                  {post.startDate} ~ {post.endDate}
                </>
              )}
              {/* 조회수 */}
              {header.key === 'viewCount' && formatCommas(post[header.key])}

              {!['index', 'checkbox', 'preview', 'isFile', 'isUsed', 'status', 'period', 'viewCount'].includes(header.key) &&
                post[header.key]}
            </Table.Cell>
          ))}
        </Table.Row>
      ))}
    </Table.Body>
  );
}
