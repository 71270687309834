import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Dialog, Section } from '@radix-ui/themes';

import PageTitle from 'components/layout/PageTitle';
import { ConfirmDialog, StateChangeDialog } from 'components/dialog';
import TableWrapper from 'components/table/TableWrapper';

import { instance } from 'api/axios.instance';
import { selectType } from 'store/slices/users';

// 지역 이슈 관리 > 신규 등록 목록 > 아젠다 요청 목록
export default function AgendaRequest() {
  const boardType = 'agendaRequest';

  // hooks
  const navigate = useNavigate();
  const type = useSelector(selectType); // 사용자 회원 분류
  const [searchParams] = useSearchParams();

  // state
  const [fetchData, setFetchData] = useState({ list: [], pagination: {} }); // 아젠다 요청 목록
  const [loading, setLoading] = useState(true); // 로딩 상태
  const [projectId, setProjectId] = useState(''); // 상태 변경 할 프로젝트 아이디
  const [reason, setReason] = useState(''); // 수정 요청 사유
  const [isOpenStateDialog, setIsOpenStateDialog] = useState(false); // 승인/수정 요청 모달
  const [isOpenRejectDialog, setIsOpenRejectDialog] = useState(false); // 수정 사유 모달
  const [isOpenWithdrawDialog, setIsOpenWithdrawDialog] = useState(false); // 요청 철회 모달

  // 테이블 헤더 (테이블 내 형식 및 이벤트 관리)
  const tableHeader = [
    { label: '번호', key: 'index', width: '64px' },
    {
      label: '프로젝트 명',
      key: 'projectName',
      width: 'auto',
      left: true,
      className: 'has-detail',
      code: 'requestId'
    },
    {
      label: '아젠다',
      key: 'agendaName',
      width: 'auto',
      left: true
    },
    {
      label: '상태',
      key: 'status',
      width: '160px',
      code: 'requestId',
      code2: 'reason',
      option: {
        label: {
          pending: type === 'user' ? '작성하기' : '요청 철회하기',
          completed: type === 'user' ? '작성 완료' : '승인하기',
          approve: '검토 완료',
          reject: type === 'user' ? '수정하기' : '수정 요청 완료',
          cancelled: '요청 철회 완료'
        },
        value: {
          pending: {
            clickEvent: id => {
              setProjectId(id);

              type !== 'user' ? setIsOpenWithdrawDialog(true) : navigate(`/agendaRequest/write/${id}?mode=write`);
            }
          },
          completed: {
            clickEvent: id => {
              setProjectId(id);
              setIsOpenStateDialog(true);
            },
            disabled: type === 'user'
          },
          approve: {
            disabled: true
          },
          reject: {
            clickEvent: (id, reason) => {
              setProjectId(id);
              setReason(reason);
              setIsOpenRejectDialog(true);
            },
            disabled: type !== 'user'
          },
          cancelled: {
            disabled: true
          }
        }
      }
    },
    { label: '등록일', key: 'createDate', width: '120px' }
  ];

  // 아젠다 요청 목록 조회 API
  const fetchList = useCallback(async () => {
    const searchTypeMap = {
      프로젝트명: 'projectName',
      아젠다: 'agendaName'
    };

    setLoading(true);
    try {
      const response = await instance('/docs/list', {
        method: 'POST',
        data: {
          pageNo: Number(searchParams.get('page')) || 1, // 페이지 번호
          searchType: searchTypeMap[searchParams.get('searchType')] || '', // 검색 카테고리
          search: searchParams.get('search') || '', // 검색어
          region: searchParams.get('region') || '' // 지역
        }
      });

      setFetchData({ pagination: response.data.data.pagination, list: response.data.data.docs });
    } finally {
      setLoading(false);
    }
  }, [searchParams]);

  // 프로젝트 상태 변경 API
  const handleUpdateStatus = async (status, content) => {
    setIsOpenStateDialog(false);
    const response = await instance('/admin/docs/examine', {
      method: 'POST',
      data: {
        id: projectId,
        status,
        reason: content
      }
    });
    if (response.data.status === 200) {
      fetchList();
    }
  };

  // 아젠다 요청 철회 API
  const handleWithdrawStatus = async () => {
    const response = await instance(`/admin/docs/cancel/${projectId}`, {
      method: 'DELETE'
    });
    if (response.data.status === 200) {
      fetchList();
    }
  };

  // mount 시 호출
  useEffect(() => {
    fetchList();
  }, [fetchList, searchParams]);

  return (
    <>
      <Section>
        <PageTitle title="아젠다 요청 목록" />
        <TableWrapper tableData={{ tableHeader, tableBody: fetchData }} boardType={boardType} loading={loading} fetchList={fetchList} />
      </Section>

      {isOpenStateDialog && (
        <Dialog.Root open={isOpenStateDialog} onOpenChange={setIsOpenStateDialog}>
          <StateChangeDialog
            requestState="승인 진행"
            leftOption={['승인 진행']}
            rightOption={['승인 완료', '수정 요청']}
            handleClick={handleUpdateStatus}
          />
        </Dialog.Root>
      )}
      {isOpenRejectDialog && (
        <Dialog.Root open={isOpenRejectDialog} onOpenChange={setIsOpenRejectDialog}>
          <ConfirmDialog
            title="수정 요청 사유"
            description={reason}
            onConfirm={() => navigate(`/agendaRequest/write/${projectId}`)}
            showTitle
            showCancel={false}
            buttonName="수정 페이지 이동"
          />
        </Dialog.Root>
      )}
      {isOpenWithdrawDialog && (
        <Dialog.Root open={isOpenWithdrawDialog} onOpenChange={setIsOpenWithdrawDialog}>
          <ConfirmDialog
            title="아젠다 요청 철회"
            description="아젠다 요청을 철회하시겠습니까?"
            onConfirm={handleWithdrawStatus}
            showTitle
          />
        </Dialog.Root>
      )}
    </>
  );
}
