import React from 'react';
import { Section } from '@radix-ui/themes';

import PageTitle from 'components/layout/PageTitle';
import BoardWrite from 'components/board/BoardWrite';

// 게시판 관리 > 홍보자료 관리 > 등록 (웹 마스터 관리자 전용)
export default function PromotionWrite() {
  const boardType = 'promotion';

  return (
    <Section>
      <PageTitle title="홍보자료 관리" />
      <BoardWrite boardType={boardType} />
    </Section>
  );
}
