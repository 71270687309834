import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Dialog, Section } from '@radix-ui/themes';

import PageTitle from 'components/layout/PageTitle';
import { TemplatePreviewDialog, ConfirmDialog } from 'components/dialog';
import TableWrapper from 'components/table/TableWrapper';

import { instance } from 'api/axios.instance';

// 프로젝트 양식 관리 > 템플릿 관리 > 목록 (프로젝트 관리자, 웹 마스터 관리자 전용)
export default function Template() {
  const boardType = 'template';

  // hooks
  const [searchParams] = useSearchParams();

  // state
  const [fetchData, setFetchData] = useState({ list: [], pagination: {} }); // 템플릿 목록
  const [loading, setLoading] = useState(true); // 로딩 상태

  const [isOpenIsUsedDialog, setIsOpenIsUsedDialog] = useState(false); // 사용 여부 변경 모달
  const [isOpenPreviewDialog, setIsOpenPreviewDialog] = useState(false); // 템플릿 미리 보기 모달
  const [isUsedState, setIsUsedState] = useState('Y'); // 사용 여부 상태 (Y || N)
  const [templateCode, setTemplateCode] = useState(''); // 사용 여부 변경할 템플릿 코드
  const [componentName, setComponentName] = useState(''); // 미리보기할 템플릿

  // 테이블 헤더 (테이블 내 형식 및 이벤트 관리)
  const tableHeader = [
    { label: '번호', key: 'index', width: '64px' },
    {
      label: '템플릿 명',
      key: 'templateName',
      width: 'auto',
      left: true
    },
    {
      label: '사용 여부',
      key: 'isUsed',
      width: '108px',
      code: 'templateCode',
      option: {
        label: { false: '사용안함', true: '사용' },
        value: {
          false: {
            clickEvent: code => {
              setIsUsedState('Y');
              setTemplateCode(code);
              setIsOpenIsUsedDialog(true);
            }
          },
          true: {
            clickEvent: code => {
              setIsUsedState('N');
              setTemplateCode(code);
              setIsOpenIsUsedDialog(true);
            }
          }
        }
      }
    },
    { label: '템플릿 사용기간', key: 'period', width: '220px' },
    { label: '등록일', key: 'createDate', width: '120px' },
    {
      label: '미리보기',
      key: 'preview',
      width: '100px',
      clickEvent: post => {
        setIsOpenPreviewDialog(true);
        setComponentName(post.componentName);
      }
    }
  ];

  // 템플릿 목록 조회 API
  const fetchList = useCallback(async () => {
    setLoading(true);

    try {
      const response = await instance('/admin/template/list', {
        method: 'POST',
        data: {
          pageNo: Number(searchParams.get('page')) || 1, // 페이지 번호
          searchType: searchParams.get('searchType') || '', // 검색 카테고리
          search: searchParams.get('search') || '' // 검색어
        }
      });

      setFetchData({ pagination: response.data.data.pagination, list: response.data.data.templates });
    } finally {
      setLoading(false);
    }
  }, [searchParams]);

  // 템플릿 사용 여부 변경 API
  const handleUpdateIsUsed = async () => {
    const response = await instance(`/admin/template/${templateCode}`, {
      method: 'PATCH',
      params: {
        use: isUsedState
      }
    });

    if (response.data.status === 201) {
      await fetchList();
    }
  };

  // mount 및 searchParams 변경 시 호출
  useEffect(() => {
    fetchList();
  }, [fetchList, searchParams]);
  return (
    <>
      <Section>
        <PageTitle title="템플릿 관리" />
        <TableWrapper tableData={{ tableHeader, tableBody: fetchData }} boardType={boardType} loading={loading} fetchList={fetchList} />
      </Section>

      {/* 사용 여부 Dialog */}
      {isOpenIsUsedDialog && (
        <Dialog.Root open={isOpenIsUsedDialog} onOpenChange={setIsOpenIsUsedDialog}>
          <ConfirmDialog
            title="사용 여부 변경"
            description={
              <>
                사용여부를 <span className="font-info">‘{isUsedState === 'N' ? '사용안함' : '사용'}’</span>으로 변경 하시겠습니까?
              </>
            }
            onConfirm={handleUpdateIsUsed}
          />
        </Dialog.Root>
      )}
      {/* 미리보기 Dialog */}
      {isOpenPreviewDialog && (
        <Dialog.Root open={isOpenPreviewDialog} onOpenChange={setIsOpenPreviewDialog}>
          <TemplatePreviewDialog componentName={componentName} />
        </Dialog.Root>
      )}
    </>
  );
}
