import React, { useState } from 'react';
import { Button, Flex, Dialog, Box, Separator, IconButton, Text } from '@radix-ui/themes';
import { Cross1Icon } from '@radix-ui/react-icons';
import { useFormik } from 'formik';
import { validationSchemaStateChange } from 'util/validationSchemas';
import { Textarea, SelectBox } from '../form';
import './style.scss';

// 상태변경 모달
export function StateChangeDialog({ requestState, leftOption, rightOption, handleClick }) {
  const [selectedLeftOption, setSelectedLeftOption] = useState(requestState);
  const [selectedRightOption, setSelectedRightOption] = useState(rightOption[0]);
  const formik = useFormik({
    initialValues: {
      reason: ''
    },
    validationSchema: selectedRightOption === '수정 요청' ? validationSchemaStateChange : null,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: values => {
      handleConfirm(values.reason);
    }
  });
  const handleConfirm = reason => {
    const status = ['등록 완료', '승인 완료'].includes(selectedRightOption) ? 'approve' : 'reject';
    handleClick(status, reason);
  };
  return (
    <Dialog.Content maxWidth="500px" className="state-dialog">
      <Dialog.Title size="4">
        <Flex align="center" justify="between">
          <Box className="dialog-title">상태 변경</Box>
          <Dialog.Close>
            <IconButton className="rt-variant-text close-btn">
              <Cross1Icon />
            </IconButton>
          </Dialog.Close>
        </Flex>
      </Dialog.Title>
      <Dialog.Description />
      <Separator size="4" mt="var(--space-5)" mb="var(--space-8)" />
      <Box>
        <Flex align="center" justify="center" gap="var(--space-4)" mb="var(--space-4)">
          <SelectBox size="2" value={selectedLeftOption} options={leftOption} onChange={value => setSelectedLeftOption(value)} />
          <img src={require('assets/images/icon/icon-arrow-img.svg').default} alt="화살표이미지" />
          <SelectBox size="2" value={selectedRightOption} options={rightOption} onChange={value => setSelectedRightOption(value)} />
        </Flex>
        <Text as="p" mb="var(--space-3)" align="center" color="gray">
          진행 상태를&nbsp; "{selectedRightOption}" 변경 하시겠습니까?
        </Text>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        {selectedRightOption === '수정 요청' && (
          <>
            <Textarea
              required
              maxLength="300"
              placeholder="수정 요청 사유를 입력하여 주세요."
              name="reason"
              value={formik.values.reason}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched?.reason && formik.errors?.reason && <Text color="red">{formik.errors?.reason}</Text>}
          </>
        )}
        <Flex gap="2" mt="var(--space-8)" justify="end">
          <Button type="submit">변경</Button>
          <Dialog.Close>
            <Button variant="outline">취소</Button>
          </Dialog.Close>
        </Flex>
      </form>
    </Dialog.Content>
  );
}
