import React, { useCallback, useEffect, useState } from 'react';
import { Button, Flex, Dialog, Box, Separator, IconButton, Text } from '@radix-ui/themes';
import { Cross1Icon } from '@radix-ui/react-icons';

import { instance } from 'api/axios.instance';
import { SelectBox } from '../form';
import './style.scss';

// 아젠다 작성 요청 모달
export function AgendaRequestDialog({ handleClick }) {
  const [selectedAgenda, setSelectedAgenda] = useState('');
  const [agendaList, setAgendaList] = useState([]);

  const handleConfirm = () => {
    handleClick(selectedAgenda);
  };

  const fetchList = useCallback(async () => {
    const response = await instance('/admin/agenda/active/list', {
      method: 'GET'
    });
    setAgendaList(
      response.data.data.map(agenda => ({
        value: agenda.code,
        label: agenda.name
      }))
    );
  }, []);

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  return (
    <Dialog.Content maxWidth="480px" className="state-dialog">
      <Dialog.Title size="4">
        <Flex align="center" justify="between">
          <Box className="dialog-title">아젠다 작성 요청</Box>
          <Dialog.Close>
            <IconButton className="rt-variant-text close-btn">
              <Cross1Icon />
            </IconButton>
          </Dialog.Close>
        </Flex>
      </Dialog.Title>
      <Dialog.Description />
      <Separator size="4" mt="var(--space-5)" mb="var(--space-8)" />

      <Flex align="center" justify="between" gap="var(--space-1)" mb="var(--space-6)">
        <Text as="p" color="gray">
          아젠다 명
        </Text>
        <SelectBox
          placeholder="아젠다를 선택해주세요"
          value={selectedAgenda}
          options={agendaList}
          onChange={value => setSelectedAgenda(value)}
          className="dialog-select-box"
        />
      </Flex>

      <Flex gap="2" mt="var(--space-8)" justify="end">
        <Dialog.Close>
          <Button disabled={!selectedAgenda} onClick={handleConfirm}>
            저장
          </Button>
        </Dialog.Close>
        <Dialog.Close>
          <Button variant="outline">취소</Button>
        </Dialog.Close>
      </Flex>
    </Dialog.Content>
  );
}
