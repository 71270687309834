import React from 'react';
import { Heading } from '@radix-ui/themes';

import './pageTitle.scss';

// 페이지 제목 컴포넌트
export default function PageTitle(props) {
  return (
    <Heading as="h3" className="title3 page-title">
      {props.title}
    </Heading>
  );
}
