import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Dialog, Section } from '@radix-ui/themes';

import TableWrapper from 'components/table/TableWrapper';
import PageTitle from 'components/layout/PageTitle';
import { ConfirmDialog } from 'components/dialog';

import { instance } from 'api/axios.instance';

// 프로젝트 양식 관리 > 아젠다 관리 > 목록 (프로젝트 관리자, 웹 마스터 관리자 전용)
export default function Agenda() {
  const boardType = 'agenda';

  // hooks
  const [searchParams] = useSearchParams();

  // state
  const [fetchData, setFetchData] = useState({ list: [], pagination: {} }); // 아젠다 목록
  const [loading, setLoading] = useState(true); // 로딩 상태
  const [isOpenIsUsedDialog, setIsOpenIsUsedDialog] = useState(false); // 사용 여부 변경 모달
  const [isUsedState, setIsUsedState] = useState(true); // 사용 여부 상태 (true || false)
  const [agendaCode, setAgendaCode] = useState(''); // 사용 여부 변경할 아젠다 코드

  // 테이블 헤더 (테이블 내 형식 및 이벤트 관리)
  const tableHeader = [
    { label: '선택', key: 'checkbox', width: '64px' },
    { label: '번호', key: 'index', width: '64px' },
    {
      label: '아젠다 명',
      key: 'agendaName',
      width: 'auto',
      left: true,
      className: 'has-detail',
      code: 'agendaCode'
    },
    {
      label: '사용 여부',
      key: 'isUsed',
      width: '108px',
      code: 'agendaCode',
      option: {
        label: { false: '사용안함', true: '사용' },
        value: {
          false: {
            clickEvent: code => {
              setIsUsedState(true);
              setAgendaCode(code);
              setIsOpenIsUsedDialog(true);
            }
          },
          true: {
            clickEvent: code => {
              setIsUsedState(false);
              setAgendaCode(code);
              setIsOpenIsUsedDialog(true);
            }
          }
        }
      }
    },
    { label: '아젠다 사용기간', key: 'period', width: '220px' },
    { label: '등록일', key: 'createDate', width: '120px' }
  ];

  // 아젠다 목록 조회 API
  const fetchList = useCallback(async () => {
    const searchTypeMap = {
      제목: 'title'
    };

    setLoading(true);
    try {
      const response = await instance('/admin/agenda/list', {
        method: 'POST',
        data: {
          pageNo: Number(searchParams.get('page')) || 1, // 페이지 번호
          search: searchParams.get('search') || '', // 검색어
          searchType: searchTypeMap[searchParams.get('searchType')] || '' // 검색 카테고리
        }
      });
      setFetchData({ pagination: response.data.data.pagination, list: response.data.data.agendas });
    } finally {
      setLoading(false);
    }
  }, [searchParams]);

  // 아젠다 사용 여부 변경 API
  const handleUpdateIsUsed = async () => {
    const response = await instance('/admin/agenda/edit', {
      method: 'POST',
      data: {
        agendaCode,
        isUsed: isUsedState
      }
    });

    if (response.data.status === 201) {
      await fetchList();
    }
  };

  // mount 및 searchParams 변경 시 호출
  useEffect(() => {
    fetchList();
  }, [fetchList, searchParams]);

  return (
    <>
      <Section>
        <PageTitle title="아젠다 관리" />
        <TableWrapper tableData={{ tableHeader, tableBody: fetchData }} boardType={boardType} loading={loading} fetchList={fetchList} />
      </Section>

      {/* 사용 여부 Dialog */}
      {isOpenIsUsedDialog && (
        <Dialog.Root open={isOpenIsUsedDialog} onOpenChange={setIsOpenIsUsedDialog}>
          <ConfirmDialog
            title="사용 여부 변경"
            description={
              <>
                사용여부를 <span className="font-info">‘{isUsedState ? '사용' : '사용안함'}’</span>으로 변경 하시겠습니까?
              </>
            }
            onConfirm={handleUpdateIsUsed}
          />
        </Dialog.Root>
      )}
    </>
  );
}
