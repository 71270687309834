import React from 'react';
import { Section } from '@radix-ui/themes';

import PageTitle from 'components/layout/PageTitle';
import BoardDetail from 'components/board/BoardDetail';

// 게시판 관리 > 홍보자료 관리 > 상세 (웹 마스터 관리자 전용)
export default function PromotionDetail() {
  const boardType = 'promotion';

  return (
    <Section>
      <PageTitle title="홍보자료" />
      <BoardDetail boardType={boardType} />
    </Section>
  );
}
