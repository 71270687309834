import { Route, Navigate } from 'react-router-dom';

// 로그인/회원가입
import Login from 'pages/login';
import FindAccount from 'pages/findAccount';
import Membership from 'pages/membership';
import MembershipComplete from 'pages/membership/MembershipComplete';
import Main from 'pages/main';

// 사업 현황 관리
import LocalStatus from 'pages/localStatus';
import LocalStatusWrite from 'pages/localStatus/Write';
import LocalStatusDetail from 'pages/localStatus/Detail';

// 실태 조사 관리
import RealSurvey from 'pages/realSurvey';
import RealSurveyWrite from 'pages/realSurvey/Write';
import RealSurveyDetail from 'pages/realSurvey/Detail';

// 프로젝트 양식 관리
import Agenda from 'pages/agenda';
import AgendaWrite from 'pages/agenda/Write';
import AgendaDetail from 'pages/agenda/Detail';
import Template from 'pages/template';

// 지역 이슈 관리
import ProjectRequest from 'pages/projectRequest';
import ProjectRequestWrite from 'pages/projectRequest/Write';
import ProjectRequestDetail from 'pages/projectRequest/Detail';
import Project from 'pages/project';
import AgendaRequest from 'pages/agendaRequest';
import AgendaRequestWrite from 'pages/agendaRequest/Write';
import AgendaRequestDetail from 'pages/agendaRequest/Detail';

// 회원 관리
import Account from 'pages/account';

// 게시판 관리
import Notice from 'pages/notice';
import NoticeWrite from 'pages/notice/Write';
import NoticeDetail from 'pages/notice/Detail';
import Promotion from 'pages/promotion';
import PromotionWrite from 'pages/promotion/Write';
import PromotionDetail from 'pages/promotion/Detail';

// 문의하기
import Question from 'pages/question';
import QuestionDetail from 'pages/question/Detail';
import QuestionWrite from 'pages/question/Write';

import { selectType } from 'store/slices/users';
import { useSelector } from 'react-redux';

// route 권한을 roles로 변경함 roles 배열에 들어있는 userType만 접근 가능함
const RouteList = [
  { path: '/login', element: <Login />, roles: [] },
  { path: '/membership', element: <Membership />, roles: [] },
  { path: '/membershipComplete', element: <MembershipComplete />, roles: [] },
  { path: '/findPassword', element: <FindAccount />, roles: [] },
  { path: '/findId', element: <FindAccount />, roles: [] },

  { path: '/', element: <Main />, roles: ['user', 'admin', 'master'] },
  // 프로젝트 양식 관리 > 아젠다 관리
  { path: '/agenda', element: <Agenda />, roles: ['admin', 'master'] },
  { path: '/agenda/write/:id?', element: <AgendaWrite />, roles: ['admin', 'master'] },
  { path: '/agenda/detail/:id', element: <AgendaDetail />, roles: ['admin', 'master'] },
  // 프로젝트 양식 관리 > 템플릿 관리
  { path: '/template', element: <Template />, roles: ['admin', 'master'] },
  // 지역 이슈 관리 > 신규 등록 목록
  { path: '/projectRequest', element: <ProjectRequest />, roles: ['user', 'admin', 'master'] },
  { path: '/projectRequest/write/:id?', element: <ProjectRequestWrite />, roles: ['user', 'master'] },
  { path: '/projectRequest/detail/:id', element: <ProjectRequestDetail />, roles: ['user', 'admin', 'master'] },
  // 지역 이슈 관리 > 프로젝트 목록
  { path: '/project', element: <Project />, roles: ['admin', 'master'] },
  { path: '/project/detail/:id', element: <ProjectRequestDetail />, roles: ['admin', 'master'] },
  // 지역 이슈 관리 > 아젠다 요청 목록
  { path: '/agendaRequest', element: <AgendaRequest />, roles: ['user', 'admin', 'master'] },
  { path: '/agendaRequest/write/:id?', element: <AgendaRequestWrite />, roles: ['user', 'master'] },
  { path: '/agendaRequest/detail/:id', element: <AgendaRequestDetail />, roles: ['user', 'admin', 'master'] },
  // 회원 관리
  { path: '/account', element: <Account />, roles: ['admin', 'master'] },

  // 사업 현황 관리
  { path: '/localStatus', element: <LocalStatus />, roles: ['master'] },
  { path: '/localStatus/write/:id?', element: <LocalStatusWrite />, roles: ['master'] },
  { path: '/localStatus/detail/:id', element: <LocalStatusDetail />, roles: ['master'] },
  // 실태 조사 관리
  { path: '/realSurvey', element: <RealSurvey />, roles: ['master'] },
  { path: '/realSurvey/write/:id?', element: <RealSurveyWrite />, roles: ['master'] },
  { path: '/realSurvey/detail/:id', element: <RealSurveyDetail />, roles: ['master'] },
  // 게시판 관리 > 공지사항 관리
  { path: '/notice', element: <Notice />, roles: ['master'] },
  { path: '/notice/write/:id?', element: <NoticeWrite />, roles: ['master'] },
  { path: '/notice/detail/:id?', element: <NoticeDetail />, roles: ['master'] },
  // 게시판 관리 > 홍보자료 관리
  { path: '/promotion', element: <Promotion />, roles: ['master'] },
  { path: '/promotion/write/:id?', element: <PromotionWrite />, roles: ['master'] },
  { path: '/promotion/detail/:id', element: <PromotionDetail />, roles: ['master'] },
  //게시판 관리 > 문의하기
  { path: '/question', element: <Question />, roles: ['master', 'admin', 'user'] },
  { path: '/question/detail/:id?', element: <QuestionDetail />, roles: ['master', 'admin', 'user'] },
  { path: '/question/write/:id?', element: <QuestionWrite />, roles: ['user'] }
];

function RedirectRoute({ isLogin, element }) {
  return isLogin ? <Navigate to="/" replace /> : element;
}

function ProtectedRoute({ element, isLogin, roles }) {
  const userType = useSelector(selectType);

  switch (userType) {
    case 'master':
      return isLogin ? element : <Navigate to="/login" replace />;
    case 'admin':
      if (roles?.includes('admin')) {
        return isLogin ? element : <Navigate to="/login" replace />;
      }
      return isLogin ? <Navigate to="/" replace /> : <Navigate to="/login" replace />;

    case 'user':
      if (roles?.includes('user')) {
        return isLogin ? element : <Navigate to="/login" replace />;
      }
      return isLogin ? <Navigate to="/" replace /> : <Navigate to="/login" replace />;

    default:
      return <Navigate to="/login" replace />;
  }
}

export const generateRoutes = isLogin => {
  return (
    <>
      {RouteList.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={
            route.roles?.length === 0 ? (
              <RedirectRoute element={route.element} isLogin={isLogin} />
            ) : (
              <ProtectedRoute element={route.element} isLogin={isLogin} roles={route.roles} />
            )
          }
        />
      ))}
      <Route path="*" element={<Navigate to="/" replace />}></Route>
    </>
  );
};

export default RouteList;
