import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Dialog, Section } from '@radix-ui/themes';

import PageTitle from 'components/layout/PageTitle';
import { AgendaRequestDialog } from 'components/dialog';
import TableWrapper from 'components/table/TableWrapper';

import { instance } from 'api/axios.instance';

// 지역 이슈 관리 > 프로젝트 목록 (프로젝트 관리자 전용)
export default function Project() {
  const boardType = 'project';

  // hooks
  const [searchParams] = useSearchParams();

  // state
  const [fetchData, setFetchData] = useState({ list: [], pagination: {} }); // 프로젝트 목록
  const [loading, setLoading] = useState(true); // 로딩 상태
  const [projectId, setProjectId] = useState(''); // 상태 변경 할 프로젝트 아이디
  const [isOpenRequestDialog, setIsOpenRequestDialog] = useState(false); // 아젠다 요청 모달

  // 테이블 헤더 (테이블 내 형식 및 이벤트 관리)
  const tableHeader = [
    { label: '번호', key: 'index', width: '64px' },
    {
      label: '프로젝트 명',
      key: 'title',
      width: 'auto',
      left: true,
      className: 'has-detail',
      code: 'id'
    },
    { label: '프로젝트 단계', key: 'stage', width: '160px' },
    {
      label: '상태',
      key: 'status',
      width: '160px',
      code: 'id',
      option: {
        label: { 아젠다요청: '아젠다 요청', 요청완료: '요청 완료' },
        value: {
          아젠다요청: {
            clickEvent: id => {
              setProjectId(id);
              setIsOpenRequestDialog(true);
            }
          },
          요청완료: {
            disabled: true
          }
        }
      }
    },
    { label: '등록일', key: 'createDate', width: '120px' }
  ];

  // 프로젝트 목록 조회 API
  const fetchList = useCallback(async () => {
    const searchTypeMap = {
      제목: 'title'
    };

    setLoading(true);
    try {
      const response = await instance('/admin/project/list', {
        method: 'POST',
        data: {
          pageNo: Number(searchParams.get('page')) || 1, // 페이지 번호
          searchType: searchTypeMap[searchParams.get('searchType')] || '', // 검색 카테고리
          search: searchParams.get('search') || '', // 검색어
          region: searchParams.get('region') || '' // 지역
        }
      });

      setFetchData({ pagination: response.data.data.pagination, list: response.data.data.projects });
    } finally {
      setLoading(false);
    }
  }, [searchParams]);

  // 프로젝트 상태 변경 API
  const handleUpdateStatus = async code => {
    const response = await instance('/admin/docs/request', {
      method: 'POST',
      data: {
        projectId,
        agendaCode: code
      }
    });
    if (response.data.status === 200) {
      fetchList();
    }
  };

  // mount 및 searchParams 변경 시 호출
  useEffect(() => {
    fetchList();
  }, [fetchList, searchParams]);

  return (
    <>
      <Section>
        <PageTitle title="프로젝트 목록" />
        <TableWrapper tableData={{ tableHeader, tableBody: fetchData }} boardType={boardType} loading={loading} fetchList={fetchList} />
      </Section>

      {isOpenRequestDialog && (
        <Dialog.Root open={isOpenRequestDialog} onOpenChange={setIsOpenRequestDialog}>
          <AgendaRequestDialog handleClick={handleUpdateStatus} />
        </Dialog.Root>
      )}
    </>
  );
}
