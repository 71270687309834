import React, { useState } from 'react';
import { Box, Text, TextArea } from '@radix-ui/themes';
import classNames from 'classnames';

export function Textarea(props) {
  const { id, maxLength, disabled, className, size, onChange } = props;
  const [content, setContent] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = e => {
    const newValue = e.target.value;
    setContent(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };
  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <Box className={classNames('textarea', { disabled, focus: isFocused }, className)}>
      <TextArea
        id={id}
        size={size}
        value={content}
        maxLength={maxLength}
        onChange={handleChange}
        placeholder={props.placeholder}
        disabled={disabled}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...props}
      />
      {maxLength && (
        <Text as="p" className="count">
          {content.length || props.value?.length || 0}/{maxLength}
        </Text>
      )}
    </Box>
  );
}
