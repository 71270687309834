import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Section, Box, Text, Table, Flex, Button, Dialog } from '@radix-ui/themes';
import PageTitle from 'components/layout/PageTitle';
import { ConfirmDialog } from 'components/dialog';

export default function RealSurveyDetail() {
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <Section>
      <PageTitle title="실태조사" />
      <Box className="box box-white">
        <Box mb="var(--space-10)">
          <Table.Root className="table-write">
            <Table.Body>
              <Table.Row align="center">
                <Table.ColumnHeaderCell width="180px">
                  <Text>제목</Text>
                </Table.ColumnHeaderCell>
                <Table.Cell width="auto">
                  <Text as="p" color="gray">
                    사업현황 제목
                  </Text>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
            <Table.Body>
              <Table.Row align="center">
                <Table.ColumnHeaderCell width="180px">
                  <Text>데이터 년도</Text>
                </Table.ColumnHeaderCell>
                <Table.Cell width="auto">
                  <Text as="p" color="gray">
                    2023년
                  </Text>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
            <Table.Body>
              <Table.Row align="center">
                <Table.ColumnHeaderCell width="180px">
                  <Text>지역</Text>
                </Table.ColumnHeaderCell>
                <Table.Cell width="auto">
                  <Text as="p" color="gray">
                    세종
                  </Text>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table.Root>
        </Box>

        <Box>
          <Text as="p" className="sub-title1">
            데이터
          </Text>
          <Box mt="var(--space-1)">
            <Text as="p" color="gray">
              강원도 고령 인구 비중은 지난해 로 높은 수치를 보임 노인 비중 증가 폭 전국 1위 현재 춘천 지역의경우봄봄버스 라는 마을버스 운행
              중에 있음. 강원도 고령 인구 비중은 지난해 로 높은 수치를 보임 노인 비중 증가 폭 전국 1위 현재 춘천 지역의경우봄봄버스 라는
              마을버스 운행 중에 있음. 강원도 고령 인구 비중은 지난해 로 높은 수치를 보임 노인 비중 증가 폭 전국 1위 현재 춘천
              지역의경우봄봄버스 라는 마을버스 운행 중에 있음. 강원도 고령 인구 비중은 지난해 로 높은 수치를 보임 노인 비중 증가 폭 전국 1위
              현재 춘천 지역의경우봄봄버스 라는 마을버스 운행 중에 있음. 강원도 고령 인구 비중은 지난해 로 높은 수치를 보임 노인 비중 증가
              폭 전국 1위 현재 춘천 지역의경우봄봄버스 라는 마을버스 운행 중에 있음. 강원도 고령 인구 비중은 지난해 로 높은 수치를 보임 노인
              비중 증가 폭 전국 1위 현재 춘천 지역의경우봄봄버스 라는 마을버스 운행 중에 있음.
            </Text>
          </Box>
        </Box>
      </Box>

      {/* 페이지 버튼 */}
      <Box>
        <Flex justify="between">
          <Button
            variant="outline"
            onClick={() => {
              navigate('/localStatus');
            }}
          >
            목록
          </Button>
          <Flex justify="end" gap="2">
            <Dialog.Root>
              <Dialog.Trigger>
                <Button onClick={() => navigate(`/realSurvey/write/${id}`)}>수정</Button>
              </Dialog.Trigger>
            </Dialog.Root>
            <Dialog.Root>
              <Dialog.Trigger>
                <Button>삭제</Button>
              </Dialog.Trigger>
              <ConfirmDialog title="컨텐츠 삭제" description="해당 내용을 제거 하시겠습니까?" />
            </Dialog.Root>
          </Flex>
        </Flex>
      </Box>
    </Section>
  );
}
