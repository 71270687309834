import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Section, Container, Box, Flex, TextField, Text, Button, Table, Dialog } from '@radix-ui/themes';
import { useFormik } from 'formik';

import PageTitle from 'components/layout/PageTitle';

import { validationSchemaAgendaRequest } from 'util/validationSchemas';
import { instance } from 'api/axios.instance';
import useRenderComponent from 'hooks/useRenderComponent';
import { ConfirmDialog } from 'components/dialog';

export default function AgendaRequestWrite() {
  // hooks
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { Components, handleRenderComponents, resetComponents } = useRenderComponent(); // 동적 컴포넌트 hook

  // state
  const [formValues, setFormValues] = useState({
    title: '',
    agendaName: '',
    agendaData: []
  });
  const [isEditMode, setIsEditMode] = useState(false); // 수정 모드
  const [validationSchema, setValidationSchema] = useState(null); // 유효성 검사 스키마
  const [isOpenSaveDialog, setIsOpenSaveDialog] = useState(false); // 프로젝트 저장 모달

  // form 유효성 검사
  const formik = useFormik({
    initialValues: formValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: values => {
      setIsOpenSaveDialog(true);
      setFormValues(values);
    }
  });

  // 아젠다 요청 등록 API
  const handleCreateAgendaRequest = async () => {
    const type = isEditMode ? 'edit' : 'create';

    const formData = new FormData();

    formValues.agendaData.forEach(data => {
      if (data.templateData.attachFiles) {
        data.templateData.attachFiles.forEach(file => {
          if (file?.name) formData.append('attachFiles', file);
        });
      }
    });

    formData.append(
      'body',
      JSON.stringify({
        requestId: id,
        ...formValues
      })
    );

    const response = await instance(`/docs/${type}`, {
      method: 'POST',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    if (response.data.status === 201) {
      navigate('/agendaRequest');
    }
  };

  // 아젠다 요청 상세 조회 API
  const fetchDocsById = useCallback(async () => {
    const response = await instance(`/docs/${id}`, {
      method: 'GET'
    });

    if (response.status === 200) {
      resetComponents();
      handleRenderComponents(response.data.data.agendaData?.map(data => data.componentName));

      if (searchParams.get('mode') !== 'write') {
        setFormValues(prev => {
          return {
            ...prev,
            title: response.data.data.projectName,
            agendaName: response.data.data.agendaName,
            agendaData: response.data.data.agendaData
          };
        });
      } else {
        setFormValues(prev => {
          return {
            ...prev,
            title: response.data.data.projectName,
            agendaName: response.data.data.agendaName
          };
        });
      }
    }
  }, [handleRenderComponents, id, resetComponents, searchParams]);

  // mount 시 searchParams mode 여부에 따라 호출
  useEffect(() => {
    if (searchParams.get('mode') === 'write') {
      setIsEditMode(false);
      fetchDocsById();
    } else {
      setIsEditMode(true);
      fetchDocsById();
    }
  }, [fetchDocsById, searchParams]);

  // mount 시 유효성 검사 동적 스키마 적용 (참여기관 및 주최, 제안자 인적 사항 템플릿 포함 여부에 따라 스키마를 동적으로 적용)
  useEffect(() => {
    setValidationSchema(
      validationSchemaAgendaRequest({
        personalInfoIndex: formValues.agendaData.findIndex(data => data.templateCode === 'TP011'),
        organizationIndex: formValues.agendaData.findIndex(data => data.templateCode === 'TP014')
      })
    );
  }, [formValues]);
  return (
    <Section className="template-form-wrap">
      <Container>
        <PageTitle title={`아젠다 요청 ${isEditMode ? '수정' : '작성'}`} />
        <form onSubmit={formik.handleSubmit}>
          <Box className="box box-white">
            <Box>
              <Table.Root className="table-write">
                <Table.Body>
                  <Table.Row align="center">
                    <Table.ColumnHeaderCell width="10%">
                      <Text className="required-start">프로젝트 명</Text>
                    </Table.ColumnHeaderCell>
                    <Table.Cell width="auto">
                      <Text as="label" htmlFor="title">
                        <TextField.Root size="2" id="title" name="title" defaultValue={formValues?.title} readOnly />
                      </Text>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
                <Table.Body>
                  <Table.Row align="center">
                    <Table.ColumnHeaderCell width="10%">
                      <Text className="required-start">아젠다 명</Text>
                    </Table.ColumnHeaderCell>
                    <Table.Cell width="auto">
                      <Text as="label" htmlFor="agendaName">
                        <TextField.Root size="2" id="agendaName" defaultValue={formValues?.agendaName} readOnly />
                      </Text>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table.Root>
            </Box>
            {/* 아젠다 */}
            <Suspense fallback={<div />}>
              {Components.map((Component, index) => (
                <Component key={index} index={index} formik={formik} setFormValues={setFormValues} isWriteMode={!isEditMode} />
              ))}
            </Suspense>
          </Box>

          <Box>
            <Flex gap="var(--space-2)" justify="end">
              <Dialog.Root>
                <Dialog.Trigger>
                  <Button size="2" variant="outline">
                    취소
                  </Button>
                </Dialog.Trigger>
                <ConfirmDialog
                  title="저장 취소"
                  description="작성중인 내용을 저장하지 않고 나가시겠습니까?"
                  onConfirm={() => navigate('/agendaRequest')}
                />
              </Dialog.Root>

              <Dialog.Root open={isOpenSaveDialog} onOpenChange={setIsOpenSaveDialog}>
                <Button type="submit" size="2" onClick={() => console.log(formik.values)}>
                  저장
                </Button>

                <ConfirmDialog title="저장 확인" description="저장하시겠습니까?" onConfirm={handleCreateAgendaRequest} />
              </Dialog.Root>
            </Flex>
          </Box>
        </form>
      </Container>
    </Section>
  );
}
