import { createSlice } from '@reduxjs/toolkit';

const commonSlice = createSlice({
  name: 'common',
  initialState: {
    selectedLocal: '전체',
    isErrorDialogOpen: false,
    errorDialogStatus: ''
  },
  reducers: {
    setSelectedLocal: (state, action) => {
      state.selectedLocal = action.payload;
    },
    setIsErrorDialogOpen: (state, action) => {
      state.isErrorDialogOpen = action.payload;
    },
    setErrorDialogStatus: (state, action) => {
      state.errorDialogStatus = action.payload;
    }
  }
});

export const { setSelectedLocal, setIsErrorDialogOpen, setErrorDialogStatus } = commonSlice.actions;
export const selectSelectedLocal = state => state.common.selectedLocal;
export const selectIsErrorDialogOpen = state => state.common.isErrorDialogOpen;
export const selectErrorDialogStatus = state => state.common.errorDialogStatus;
export default commonSlice;
