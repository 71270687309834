import { instance } from 'api/axios.instance';

class UploadAdapter {
  constructor(loader, board) {
    this.loader = loader; // 파일을 로드하는 loader
    this.board = board; // 페이지에서 전달받은 board 정보
    this.abortController = new AbortController();
  }

  async upload() {
    try {
      const file = await this.loader.file;
      const formData = new FormData();
      formData.append('file', file);
      if (this.board) {
        formData.append('board', new Blob([this.board], { type: 'application/json' }));
      }

      const response = await instance(`/file/upload/${this.board ? '/temp' : '/template'}`, {
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      const data = await response.data;
      return {
        default: data.data // 성공적인 경우 URL 반환
      };
    } catch (error) {
      console.error('Upload failed: ', error);
      throw error;
    }
  }
}

export default UploadAdapter;
