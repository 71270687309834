import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes } from 'react-router-dom';
import { Theme, Container, Flex, Dialog } from '@radix-ui/themes';
import '@radix-ui/themes/styles.css';

import Header from 'components/layout/Header';
import Aside from 'components/layout/Aside';
import { ErrorDialog } from 'components/dialog';

import { generateRoutes } from 'app/router';
import { instance } from 'api/axios.instance';
import { logoutUser, selectIsLogin } from 'store/slices/users';
import { selectIsErrorDialogOpen, setIsErrorDialogOpen, setErrorDialogStatus } from 'store/slices/common';
import 'assets/scss/tokens/df-admin/token.scss';

export default function App() {
  const isLogin = useSelector(selectIsLogin);
  const isErrorDialogOpen = useSelector(selectIsErrorDialogOpen);
  const dispatch = useDispatch();

  useEffect(() => {
    const responseInterceptor = instance.interceptors.response.use(
      response => {
        console.debug(`response => ${response.config.url}`, response);
        return response;
      },
      err => {
        const { response } = err;
        console.debug(`err => ${err}`);
        const { status } = response ?? { status: 500 };

        if (status !== 200) {
          dispatch(setIsErrorDialogOpen(true));
          dispatch(
            setErrorDialogStatus({
              status: status,
              message: response?.data.message || '서버 에러가 발생했습니다.'
            })
          );
          setTimeout(() => {
            dispatch(setIsErrorDialogOpen(false));
            dispatch(
              setErrorDialogStatus({
                status: null,
                message: ''
              })
            );
            if (status === 401) {
              dispatch(logoutUser());
            }
          }, 2000);
        }

        return Promise.reject(response);
      }
    );
    return () => {
      instance.interceptors.response.eject(responseInterceptor);
    };
  }, [dispatch]);

  return (
    <Theme accentColor="blue" radius="none">
      <Container>
        {isLogin && <Header />}
        <Flex width="100%">
          {isLogin && <Aside />}
          <main id="contents" className={isLogin ? 'bg-gray' : 'bg-white'}>
            <Routes>{generateRoutes(isLogin)}</Routes>
          </main>
        </Flex>
      </Container>
      {isErrorDialogOpen && (
        <Dialog.Root open={isErrorDialogOpen} onOpenChange={setIsErrorDialogOpen}>
          <ErrorDialog />
        </Dialog.Root>
      )}
    </Theme>
  );
}
