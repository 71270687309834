import React from 'react';
import { Dialog, IconButton, VisuallyHidden } from '@radix-ui/themes';
import { Cross1Icon } from '@radix-ui/react-icons';
import { useSelector, useDispatch } from 'react-redux';

import { selectErrorDialogStatus, setIsErrorDialogOpen } from 'store/slices/common';
import './style.scss';

// 서버 에러 모달
export function ErrorDialog() {
  const dispatch = useDispatch();
  const errorDialogStatus = useSelector(selectErrorDialogStatus);
  return (
    <Dialog.Content maxWidth="400px" className="basic-dialog">
      <IconButton onClick={() => dispatch(setIsErrorDialogOpen(false))} className="rt-variant-text close-btn">
        <Cross1Icon />
      </IconButton>

      <VisuallyHidden>
        <Dialog.Title>서버 에러 안내</Dialog.Title>
      </VisuallyHidden>

      <Dialog.Description size="2" mb="var(--space-7)">
        {errorDialogStatus.status === 401 ? (
          <>
            로그인이 만료되었거나 <br />
            다른기기에서 로그인되어 로그아웃되었습니다.
            <br />
            다시 로그인해주세요.
          </>
        ) : (
          errorDialogStatus.message
        )}
      </Dialog.Description>
    </Dialog.Content>
  );
}
