import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Flex, Heading, Separator, Text, IconButton, Link, Dialog } from '@radix-ui/themes';
import { FileIcon, ImageIcon, Link2Icon } from '@radix-ui/react-icons';
import { useSelector } from 'react-redux';
import { Attachment } from 'components/form';
import { ConfirmDialog } from 'components/dialog';
import Editor from 'components/editor';
import { CommentsDialog } from 'components/dialog/CommentsDialog';
import { userInfo, selectType } from 'store/slices/users';
import { instance } from 'api/axios.instance';
import BoardComment from 'components/board/BoardComment';
import './boardDetail.scss';

// 게시판 관리 > 상세 테이블 컴포넌트
export default function BoardDetail({ boardType }) {
  // hooks
  const navigate = useNavigate();
  const { id: postId } = useParams();
  const userData = useSelector(userInfo);
  const type = useSelector(selectType);
  // state
  const [postDetail, setPostDetail] = useState({}); // 게시물 상세 데이터

  const [isDialogOpen, setDialogOpen] = useState(false); // 팝업 상태 추가
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false); // 문의 접수 완료 확인 팝업 상태 추가
  // 문의하기 답변하기, 문의 접수하기 버튼
  const actionButtons = [
    { status: 'pending', label: '문의 접수하기', variant: 'outline' },
    { status: 'processing', label: '답변 완료하기', variant: 'default' },
    { status: 'completed', label: '문의 접수하기', variant: 'outline' }
  ];

  // 게시물 상세 조회 API
  const fetchPostDetail = useCallback(async () => {
    const response = await instance(`/post/${postId}`, {
      method: 'GET'
    });

    setPostDetail(response.data.data);
  }, [postId]);

  //문의하기 접수/답변 API

  const fetchCommentsStatus = async (status, contents) => {
    const response = await instance('/post/comment', {
      method: 'POST',
      data: {
        postNo: postId,
        commentType: 'answer',
        content: contents,
        commentStatus: status === 'comments' ? '' : status
      }
    });

    if (response.data.status === 201) {
      await fetchPostDetail();
      setDialogOpen(false);
      if (status !== 'comments') {
        setConfirmDialogOpen(true);
      }
    }
  };
  // 문의하기 수정/삭제 API

  const fetchCommentsUpdate = async (commentsNo, contents, status) => {
    const response = await instance('/post/comment/edit', {
      method: 'POST',
      data: {
        commentNo: commentsNo,
        content: contents,
        isDeleted: status
      }
    });
    if (response.data.status === 201) {
      await fetchPostDetail();
    }
  };

  // 게시물 삭제 API
  const deletePost = useCallback(async () => {
    const response = await instance(`/post/${postId}`, {
      method: 'DELETE'
    });
    if (response.data.status === 200 || response.data.status === 201) {
      navigate(`/${boardType}`);
    }
  }, [postId, navigate, boardType]);

  // 유튜브 유효성 검사 method
  // const isValidYouTubeEmbed = htmlString => {
  //   try {
  //     // DOMParser를 사용해 HTML을 파싱
  //     const parser = new DOMParser();
  //     const doc = parser.parseFromString(htmlString, 'text/html');
  //     const iframe = doc.querySelector('iframe');

  //     // iframe이 없거나 src가 유효하지 않으면 false 반환
  //     if (!iframe || !iframe.src) return false;

  //     // src URL이 유튜브 embed URL인지 확인
  //     const validYouTubeDomains = ['www.youtube.com', 'youtube.com'];
  //     const url = new URL(iframe.src);

  //     return validYouTubeDomains.includes(url.hostname) && url.pathname.startsWith('/embed/');
  //   } catch (error) {
  //     console.error('Invalid HTML:', error);
  //     return false;
  //   }
  // };

  // 유튜브 렌더링 method
  // const renderVideo = videoHTML => {
  //   if (!videoHTML) return null;

  //   // 유효성 검증
  //   if (!isValidYouTubeEmbed(videoHTML)) {
  //     return <p>Invalid YouTube video.</p>;
  //   }

  //   return (
  //     <Box className="video-container">
  //       {/* 검증된 iframe HTML 삽입 */}
  //       <div dangerouslySetInnerHTML={{ __html: videoHTML }} />
  //     </Box>
  //   );
  // };

  // mount 시 호출
  useEffect(() => {
    fetchPostDetail();
  }, [fetchPostDetail]);

  const isAuthor = userData?.userId === postDetail?.post?.createUser;

  return (
    <>
      {/* 게시판 제목 */}
      <Box className="box box-white">
        <Box className="post-title">
          <Heading as="h4" className="title3">
            {postDetail?.post?.title}
          </Heading>
        </Box>
        <Flex align="center" justify="between" className="post-info">
          {/* 홍보자료일 경우 */}
          {['promotion'].includes(boardType) && (
            <Flex align="center" gap="var(--space-4)" flexGrow="1" flexShrink="1">
              {/* NOTE 홍보자료 지역 */}
              <Text as="span" className="body2">
                {postDetail.post?.region || ''}
              </Text>
              <Flex align="center" gap="4" ml="auto">
                <Text as="p" className="body2">
                  행사 시작일 : {postDetail?.post?.startDate}
                </Text>
                <Separator orientation="vertical" />
                <Text as="p" className="body2">
                  행사 종료일 : {postDetail?.post?.endDate}
                </Text>
              </Flex>
            </Flex>
          )}
          {/* 문의하기일 경우 */}
          {['question'].includes(boardType) && (
            <Flex align="center" className="post-info">
              {/* NOTE 문의하기 작성 유저 */}
              <Text as="span" className="body2">
                [{postDetail?.post?.region}]&nbsp;{postDetail.post?.createUser || ''}
              </Text>
              <Flex align="center" gap="4" ml="auto">
                <Text as="p" className="body2">
                  등록일 : {postDetail.post?.createDate?.slice(0, 10) || '-'}
                </Text>
                <Separator orientation="vertical" />
                <Text as="p" className="body2">
                  운영상태 :&nbsp;
                  <Text as="span" className="sub-title2 font-info">
                    {postDetail.post?.commentStatus === 'pending'
                      ? '문의중'
                      : postDetail.post?.commentStatus === 'processing'
                        ? '접수 완료'
                        : postDetail.post?.commentStatus === 'completed'
                          ? '답변완료'
                          : postDetail.post?.commentStatus}
                  </Text>
                </Text>
              </Flex>
            </Flex>
          )}
          {/* 공지사항일 경우 */}
          {['notice'].includes(boardType) && (
            <Flex align="center" gap="var(--space-4)" flexGrow="1" flexShrink="1">
              {/* NOTE 공지사항 지역 */}
              <Text as="span" className="body2">
                {postDetail.post?.region || ''}
              </Text>
              <Flex align="center" gap="5" ml="auto">
                <Text as="p" className="body2">
                  조회 : {postDetail?.post?.viewCount}
                </Text>
                <Separator orientation="vertical" />
                <Text as="p" className="body2">
                  등록일 : {postDetail?.post?.createDate?.slice(0, 10) || '-'}
                </Text>
              </Flex>
            </Flex>
          )}
        </Flex>
      </Box>
      {/* 게시판 본문 내용 */}
      {postDetail?.post?.content && (
        <Box className="box box-white">
          <Box className="post-box">
            {/* {postDetail.post?.videoLink && renderVideo(postDetail.post?.videoLink)} */}
            <Editor readOnly editorData={postDetail?.post?.content || ''} />
          </Box>
        </Box>
      )}
      {/* 동영상 링크 */}
      {postDetail?.post?.videoLink && (
        <Box className="box box-white">
          <Flex align="start" gap="var(--space-3)" className="post-link">
            <Flex align="center" gap="var(--space-1)" py="var(--space-4)">
              <IconButton size="1" className="icon-btn">
                <Link2Icon width={24} height={24} />
              </IconButton>
              <Text as="p" className="sub-title1">
                동영상 링크
              </Text>
            </Flex>
            <Box className="post-outline">
              <Link
                href={`https://${postDetail?.post?.videoLink?.replace(/(^\w+:|^)\/\//, '')}`}
                color="gray"
                underline="always"
                target="_blank"
                rel="noopener noreferrer"
                truncate
              >
                <Text className="body2">{postDetail?.post?.videoLink}</Text>
              </Link>
            </Box>
          </Flex>
        </Box>
      )}
      {/* 파일 첨부 */}
      {postDetail?.files?.length > 0 && (
        <Box className="box box-white">
          <Flex align="start" gap="var(--space-3)" className="post-link">
            <Flex align="center" gap="var(--space-1)" py="var(--space-4)">
              <IconButton size="1" className="icon-btn">
                <FileIcon width={24} height={24} />
              </IconButton>
              <Heading as="p" className="sub-title1" mr="var(--space-5)">
                첨부 파일
              </Heading>
            </Flex>
            <Attachment data={postDetail?.files} />
          </Flex>
        </Box>
      )}
      {/* 썸네일 등록 (홍보자료 전용) */}
      {postDetail?.thumbnails?.length > 0 && (
        <Box className="box box-white">
          <Flex align="start" gap="var(--space-3)" className="post-link">
            <Flex align="center" gap="var(--space-1)" py="var(--space-4)">
              <IconButton size="1" className="icon-btn">
                <ImageIcon width={24} height={24} />
              </IconButton>
              <Text as="p" className="sub-title1" mr="var(--space-5)">
                썸네일
              </Text>
            </Flex>
            <Box>
              <img
                src={postDetail.thumbnails[0].fileUrl}
                alt="썸네일이미지"
                style={{ width: '200px', height: 'auto' }} // 임의 사이즈 제한
              />
              <Text as="p" color="gray" mt="var(--space-2)" className="body2">
                {postDetail?.thumbnails[0]?.originalName}
              </Text>
            </Box>
          </Flex>
        </Box>
      )}
      {/* 관련 링크 */}
      {postDetail?.post?.link && (
        <Box className="box box-white">
          <Flex align="start" gap="var(--space-3)" className="post-link">
            <Flex align="center" gap="var(--space-1)" py="var(--space-4)">
              <IconButton size="1" className="rt-variant-text">
                <Link2Icon width={24} height={24} />
              </IconButton>
              <Text as="p" className="sub-title1">
                관련 링크
              </Text>
            </Flex>
            <Box className="post-outline">
              <Link
                href={`https://${postDetail?.post?.link?.replace(/(^\w+:|^)\/\//, '')}`}
                color="gray"
                underline="always"
                target="_blank"
                rel="noopener noreferrer"
                truncate
              >
                <Text className="body2">{postDetail?.post?.link}</Text>
              </Link>
            </Box>
          </Flex>
        </Box>
      )}
      {/* 게시판 하단 액션 (삭제, 목록, 수정) */}
      <Flex justify="between">
        <Button
          variant="outline"
          onClick={() => {
            navigate(`/${boardType}`);
          }}
        >
          목록
        </Button>
        <Flex gap="2">
          {isAuthor && (
            <>
              <Button
                onClick={() => {
                  navigate(`/${boardType}/write/${postId}`);
                }}
              >
                수정
              </Button>
              <Dialog.Root>
                <Dialog.Trigger>
                  <Button>삭제</Button>
                </Dialog.Trigger>
                <ConfirmDialog title="게시글 삭제" description="해당 게시글을 삭제하시겠습니끼?" onConfirm={deletePost} />
              </Dialog.Root>
            </>
          )}

          <>
            {actionButtons.map(
              ({ status, label, variant, disabled }) =>
                postDetail?.post?.commentStatus === status &&
                type !== 'user' && (
                  <Button key={status} variant={variant} disabled={disabled} onClick={() => setDialogOpen(true)}>
                    {label}
                  </Button>
                )
            )}
          </>
        </Flex>
      </Flex>
      {/* 문의 하기 답변 컴포넌트 */}
      {boardType === 'question' && <BoardComment data={postDetail} onClick={fetchCommentsStatus} update={fetchCommentsUpdate} />}

      <>
        {isDialogOpen && (
          <Dialog.Root open={isDialogOpen} onOpenChange={setDialogOpen}>
            <CommentsDialog
              status={postDetail?.post?.commentStatus}
              onClick={fetchCommentsStatus}
              title={postDetail?.post?.commentStatus === 'processing' ? '답변 완료하기' : '문의 접수하기'}
              subTitle={
                postDetail?.post?.commentStatus === 'processing'
                  ? '답변을 완료 상태로 변경하시겠습니까?'
                  : '문의 접수 완료 상태로 변경 하시겠습니까?'
              }
            />
          </Dialog.Root>
        )}
        {isConfirmDialogOpen && (
          <Dialog.Root open={isConfirmDialogOpen} onOpenChange={setConfirmDialogOpen}>
            <ConfirmDialog
              title="저장되었습니다."
              description={`${postDetail?.post?.commentStatus === 'processing' ? '문의 접수가 ' : '답변이 '}완료상태로 변경되었습니다.`}
              showCancel={false}
            />
          </Dialog.Root>
        )}
      </>
    </>
  );
}
