import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Flex, Section, Text, TextField, Dialog, Table, IconButton } from '@radix-ui/themes';
import { EyeOpenIcon } from '@radix-ui/react-icons';
import { useFormik } from 'formik';
import { format } from 'date-fns';

import PageTitle from 'components/layout/PageTitle';
import { CheckBox, DatePickers } from 'components/form';
import { TemplatePreviewDialog, ConfirmDialog } from 'components/dialog';

import { validationSchemaAgenda } from 'util/validationSchemas';
import { instance } from 'api/axios.instance';

// 프로젝트 양식 관리 > 아젠다 관리 > 등록/수정 (프로젝트 관리자, 웹 마스터 관리자 전용)
export default function AgendaWrite() {
  // hooks
  const { id } = useParams();
  const navigate = useNavigate();

  // state
  const [isEditMode, setIsEditMode] = useState(false); // 수정 모드
  const [isOpenSaveDialog, setIsOpenSaveDialog] = useState(false); // 저장 모달
  const [formValues, setFormValues] = useState({
    agendaName: '', // 아젠다 명
    templates: [], // 사용할 템플릿
    isUsed: true, // 아젠다 사용 여부
    startDate: format(new Date(), 'yyyy-MM-dd'), // 사용 기간 시작 일자
    endDate: format(new Date().setMonth(new Date().getMonth() + 3), 'yyyy-MM-dd') // 사용 기간 종료 일자
  });
  const [componentName, setComponentName] = useState(''); // 미리보기할 템플릿

  // form 유효성 검사
  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validationSchemaAgenda,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async values => {
      setFormValues(values);
      setIsOpenSaveDialog(true);
    }
  });

  // 템플릿 사용 여부 체크 Method
  const handleCheckTemplate = (e, templateCode) => {
    const templateIndex = formValues.templates.findIndex(template => template.templateCode === templateCode);

    setFormValues(prev => {
      const newObj = { ...prev };
      newObj.templates[templateIndex].isUsed = e;

      return {
        ...prev,
        templates: newObj.templates
      };
    });
  };

  // 아젠다 상세 조회 API
  const fetchAgendaDetail = useCallback(async () => {
    const response = await instance(`/admin/agenda/${id}`, {
      method: 'GET'
    });

    setFormValues(response.data.data);
  }, [id]);

  // 템플릿 목록 조회 API
  const fetchTemplateList = useCallback(async () => {
    const response = await instance('/admin/template/list', {
      method: 'GET'
    });

    setFormValues(prev => {
      return {
        ...prev,
        templates: response.data.data
      };
    });
  }, []);

  // 아젠다 등록/수정 API
  const fetchAgendaCreateOrEdit = async () => {
    const type = isEditMode ? 'edit' : 'create';
    const response = await instance(`/admin/agenda/${type}`, {
      method: 'POST',
      data: {
        ...formValues,
        templates: formValues.templates.filter(template => template.isUsed).map(template => template.templateCode)
      }
    });

    if (response.data.status === 201) {
      navigate('/agenda');
    }
  };

  // mount 시 param id 여부에 따라 호출
  useEffect(() => {
    if (id) {
      setIsEditMode(true);
      fetchAgendaDetail();
    } else {
      setIsEditMode(false);
      fetchTemplateList();
    }
  }, [id, fetchAgendaDetail, fetchTemplateList]);

  return (
    <Section>
      <PageTitle title={`프로젝트 아젠다 ${isEditMode ? '수정' : '생성'}`} />

      <form onSubmit={formik.handleSubmit}>
        <Box className="box box-white">
          <Table.Root className="table-write">
            <Table.Body>
              <Table.Row align="center">
                <Table.ColumnHeaderCell colSpan={2}>
                  <Text className="required-start">아젠다 명</Text>
                </Table.ColumnHeaderCell>
                <Table.Cell colSpan={10}>
                  <Text as="label" htmlFor="title">
                    <TextField.Root
                      size="2"
                      id="agenda"
                      required
                      placeholder="제목을 입력하세요."
                      value={formik.values?.agendaName}
                      onChange={formik.handleChange('agendaName')}
                      onBlur={formik.handleBlur('agendaName')}
                    />
                  </Text>
                  {formik.touched.agendaName && formik.errors.agendaName && (
                    <Text as="p" mt="var(--space-1)" className="font-error body3">
                      {formik.errors.agendaName}
                    </Text>
                  )}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
            <Table.Body>
              <Table.Row align="center">
                <Table.ColumnHeaderCell colSpan={2}>
                  <Text>사용여부</Text>
                </Table.ColumnHeaderCell>
                <Table.Cell colSpan={10}>
                  <Flex align="center">
                    <CheckBox checked={formik.values?.isUsed} onCheckedChange={e => formik.setFieldValue('isUsed', e)} id="checkbox" />
                    <Text as="label" htmlFor="checkbox" color="gray">
                      사용
                    </Text>
                  </Flex>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
            <Table.Body>
              <Table.Row align="center">
                <Table.ColumnHeaderCell colSpan={2}>
                  <Text className="required-start">사용 기간</Text>
                </Table.ColumnHeaderCell>
                <Table.Cell colSpan={10}>
                  <Flex align="center" gap="var(--space-2)">
                    <DatePickers
                      value={formik.values?.startDate}
                      onChange={formik.handleChange('startDate')}
                      onBlur={formik.handleBlur('startDate')}
                    />{' '}
                    <Text color="gray">~</Text>{' '}
                    <DatePickers
                      value={formik.values?.endDate}
                      onChange={formik.handleChange('endDate')}
                      onBlur={formik.handleBlur('endDate')}
                      min={formik.values?.startDate}
                    />
                  </Flex>
                  {((formik.touched.startDate && formik.errors.startDate) || (formik.touched.startDate && formik.errors.endDate)) && (
                    <Text as="p" mt="var(--space-1)" className="font-error body3">
                      {formik.errors.startDate || formik.errors.endDate}
                    </Text>
                  )}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table.Root>
        </Box>

        <Box className="box box-white">
          <Box className="basic-table">
            <Table.Root variant="surface" size="3">
              <caption>아젠다 관리</caption>
              <Table.Header>
                <Table.Row align="center">
                  <Table.ColumnHeaderCell justify="center" width="300px">
                    템플릿 명
                  </Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell justify="center" width="100px">
                    사용 여부
                  </Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell justify="center" width="auto">
                    단계 설명
                  </Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell justify="center" width="100px">
                    미리보기
                  </Table.ColumnHeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {formValues.templates?.map((el, i) => (
                  <Table.Row key={i} align="center">
                    <Table.Cell justify="center">{el.templateName}</Table.Cell>
                    <Table.Cell justify="center">
                      <Flex align="center" justify="center">
                        <CheckBox checked={el?.isUsed} onCheckedChange={e => handleCheckTemplate(e, el.templateCode)} id="checkbox" />
                      </Flex>
                    </Table.Cell>
                    <Table.Cell justify="center">
                      <Text color="gray">{el?.stageDesc}</Text>
                    </Table.Cell>

                    <Table.Cell justify="center">
                      <Dialog.Root>
                        <Dialog.Trigger>
                          <IconButton
                            onClick={() => {
                              setComponentName(el.componentName);
                            }}
                            className="rt-variant-text"
                          >
                            <EyeOpenIcon width={24} height={24} />
                          </IconButton>
                        </Dialog.Trigger>
                        <TemplatePreviewDialog componentName={componentName} />
                      </Dialog.Root>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table.Root>
          </Box>
        </Box>

        <Box>
          <Flex gap="var(--space-2)" justify="end">
            {isEditMode ? (
              <Dialog.Root>
                <Dialog.Trigger>
                  <Button size="2" type="submit">
                    저장
                  </Button>
                </Dialog.Trigger>
                <ConfirmDialog title="저장 확인" description="저장하시겠습니까?" onConfirm={fetchAgendaCreateOrEdit} />
              </Dialog.Root>
            ) : (
              <Dialog.Root open={isOpenSaveDialog} onOpenChange={setIsOpenSaveDialog}>
                <Button size="2" type="submit">
                  등록
                </Button>

                <ConfirmDialog title="저장 확인" description="저장하시겠습니까?" onConfirm={fetchAgendaCreateOrEdit} />
              </Dialog.Root>
            )}

            <Link to="/agenda">
              <Button size="2" variant="outline">
                취소
              </Button>
            </Link>
          </Flex>
        </Box>
      </form>
    </Section>
  );
}
