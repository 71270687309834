import { useState, forwardRef } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as Accordion from '@radix-ui/react-accordion';
import classNames from 'classnames';
import { ChevronDownIcon } from '@radix-ui/react-icons';

import { selectType } from 'store/slices/users';
import './aside.scss';

// 좌측 Aside 바
export default function Aside() {
  // hooks
  const location = useLocation();
  const type = useSelector(selectType);

  // state
  const [isAsideVisible, setIsAsideVisible] = useState(false); // Aside 바 노출 여부

  const allMenus = [
    {
      title: '사업 현황 관리',
      links: [{ path: '/localStatus', label: '사업 현황' }],
      level: 1
    },
    {
      title: '실태 조사 관리',
      links: [{ path: '/realSurvey', label: '실태 조사' }],
      level: 1
    },
    {
      title: '프로젝트 양식 관리',
      links: [
        { path: '/agenda', label: '아젠다 관리' },
        { path: '/template', label: '템플릿 관리' }
      ],
      level: 2
    },
    {
      title: '지역 이슈 관리',
      links: [
        { path: '/projectRequest', label: '신규 등록 목록' },
        { path: '/project', label: '프로젝트 목록' },
        { path: '/agendaRequest', label: '아젠다 요청 목록' }
      ],
      level: 3
    },
    {
      title: '회원 관리',
      links: [{ path: '/account', label: '회원 관리' }],
      level: 2
    },
    {
      title: '게시판 관리',
      links: [
        { path: '/notice', label: '공지사항 관리' },
        { path: '/promotion', label: '홍보자료 관리' }
      ],
      level: 1
    },
    {
      title: '문의하기',
      links: [{ path: '/question', label: '문의하기' }],
      level: 3
    }
  ];

  const AccordionTrigger = forwardRef(({ children, className, ...props }, forwardedRef) => (
    <Accordion.Header className="AccordionHeader">
      <Accordion.Trigger className={classNames('AccordionTrigger', className)} {...props} ref={forwardedRef}>
        {children}
        <ChevronDownIcon className="AccordionChevron" aria-hidden />
      </Accordion.Trigger>
    </Accordion.Header>
  ));

  const AccordionContent = forwardRef(({ children, className, ...props }, forwardedRef) => (
    <Accordion.Content className={classNames('AccordionContent', className)} {...props} ref={forwardedRef}>
      <div className="AccordionContentText">{children}</div>
    </Accordion.Content>
  ));

  const filteredMenus = allMenus.filter(menu => {
    if (type === 'master') {
      return [1, 2, 3].includes(menu.level);
    }
    if (type === 'admin') {
      return [2, 3].includes(menu.level);
    }
    if (menu.title === '지역 이슈 관리') {
      menu.links = menu.links.filter(link => link.path !== '/project');
    }
    return menu.level === 3;
  });

  const defaultAccordionValue = filteredMenus.reduce((acc, menu, menuIndex) => {
    const linkIndex = menu.links.findIndex(link => location.pathname.startsWith(link.path));
    return linkIndex !== -1 ? `item-${menuIndex}` : acc;
  }, null);

  return (
    <aside className={classNames('vertical', { active: isAsideVisible })}>
      <Accordion.Root className="AccordionRoot" type="single" defaultValue={defaultAccordionValue}>
        {filteredMenus.map((menu, menuIndex) => (
          <Accordion.Item value={`item-${menuIndex}`} key={`item-${menuIndex}`}>
            <AccordionTrigger>{menu.title}</AccordionTrigger>
            <AccordionContent>
              <ul>
                {menu.links.map(link => (
                  <li key={link.path}>
                    <RouterLink
                      to={link.path}
                      className={classNames('AccordionItem', {
                        active: location.pathname === link.path || new RegExp(`^${link.path}/(detail|write).*$`).test(location.pathname)
                      })}
                    >
                      {menu.children}
                      {link.label}
                    </RouterLink>
                  </li>
                ))}
              </ul>
            </AccordionContent>
          </Accordion.Item>
        ))}
      </Accordion.Root>
      <button
        type="button"
        className={classNames('folding-button', { active: isAsideVisible })}
        onClick={() => setIsAsideVisible(!isAsideVisible)}
      >
        {isAsideVisible ? '네비게이션 열기' : '네비게이션 가리기'}
      </button>
    </aside>
  );
}
