import React, { useState } from 'react';
import { Box, Button, Flex, Separator, Text, IconButton, Dialog } from '@radix-ui/themes';
import { ChatBubbleIcon, Pencil1Icon, TrashIcon } from '@radix-ui/react-icons';
import { Textarea } from 'components/form';
import { useFormik } from 'formik';
import { validationSchemaReject } from 'util/validationSchemas';
import { ConfirmDialog } from 'components/dialog';
import { userInfo } from 'store/slices/users';
import { useSelector } from 'react-redux';
export default function BoardComment({ data, onClick, update }) {
  const comments = data?.comments || [];
  const [editingCommentIndex, setEditingCommentIndex] = useState(null);
  const [commentToDeleteIndex, setCommentToDeleteIndex] = useState(null);
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [isDeleteSuccessDialogOpen, setDeleteSuccessDialogOpen] = useState(false); // 삭제 성공 팝업 상태 추가
  const userData = useSelector(userInfo);
  // const isAuthor = userData?.userId === postDetail?.post?.createUser;

  const formik = useFormik({
    initialValues: {
      reason: ''
    },
    validationSchema: validationSchemaReject,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async values => {
      if (editingCommentIndex === null) {
        await onClick('comments', values.reason);
      } else {
        const commentId = comments[editingCommentIndex]?.commentNo;
        await update(commentId, values.reason, false);
        setEditingCommentIndex(null);
      }
      formik.resetForm();
    }
  });

  const handleEdit = (index, content) => {
    setEditingCommentIndex(index);
    formik.setFieldValue('reason', content);
  };

  const handleCancel = () => {
    setEditingCommentIndex(null);
    formik.resetForm();
  };

  const handleDeleteClick = index => {
    setConfirmDialogOpen(true);
    setCommentToDeleteIndex(index);
  };

  const handleDeleteConfirm = async () => {
    const commentId = comments[commentToDeleteIndex]?.commentNo;

    try {
      await update(commentId, '', true); // 삭제 API 호출
      setConfirmDialogOpen(false);
      setDeleteSuccessDialogOpen(true); // 삭제 성공 시 팝업 표시
    } catch (error) {
      console.error('삭제 실패 :', error); // 실패 시 에러 로그
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box className="box box-white" style={{ marginTop: '16px' }}>
        <Flex align="center" gap="1" mb="4">
          <ChatBubbleIcon width={18} height={18} />
          <Text as="p" className="sub-title1">
            댓글
          </Text>
          <Text as="span" className="sub-title1 font-info">
            {comments.length}
          </Text>
        </Flex>
        <Flex direction="column" gap="2" mb="6">
          {comments.map((comment, index) => (
            <Box key={index} className="post-comment">
              {editingCommentIndex === index ? (
                <>
                  <Flex align="center" gap="4" mb="4" px="3" className="post-comment-head">
                    <Text className="body2 font-basic">{comment.userId}</Text>
                    <Separator orientation="vertical" />
                    <Text className="body2 font-basic">{comment.createDate?.slice(0, 10)}</Text>
                  </Flex>
                  <Box className="post-comment-body">
                    <Textarea
                      value={formik.values.reason}
                      onChange={formik.handleChange}
                      placeholder="댓글 수정"
                      size="3"
                      className="height-md"
                      name="reason"
                      onBlur={formik.handleBlur}
                    />
                  </Box>
                  <Flex justify="end" gap="2" mt="4">
                    <Button type="submit">저장</Button>
                    <Button variant="outline" onClick={handleCancel}>
                      취소
                    </Button>
                  </Flex>
                </>
              ) : (
                <>
                  <Flex align="center" gap="4" mb="4" px="3" className="post-comment-head">
                    <Text className="body2 font-basic">{comment.userId}</Text>
                    <Separator orientation="vertical" />
                    <Text className="body2 font-basic">{comment.createDate?.slice(0, 10)}</Text>
                    {userData?.userId === comment?.userId && (
                      <Flex align="center" ml="auto">
                        <IconButton variant="ghost" type="button" onClick={() => handleEdit(index, comment.content)}>
                          <Pencil1Icon width={18} height={18} />
                          <Text className="body3">수정</Text>
                        </IconButton>
                        <IconButton variant="ghost" type="button" onClick={() => handleDeleteClick(index)}>
                          <TrashIcon width={18} height={18} />
                          <Text className="body3">삭제</Text>
                        </IconButton>
                      </Flex>
                    )}
                  </Flex>
                  <Box className="post-comment-body">
                    <Text className="body2 font-basic">{comment.content}</Text>
                  </Box>
                </>
              )}
            </Box>
          ))}

          <>
            <Textarea
              placeholder="댓글입력"
              size="3"
              className="height-md"
              name="reason"
              value={editingCommentIndex === null ? formik.values.reason : ''}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Flex justify="end" gap="2" mt="6">
              <Button type="submit">저장</Button>
              <Button variant="outline" onClick={formik.resetForm}>
                취소
              </Button>
            </Flex>
          </>
        </Flex>
      </Box>
      {isConfirmDialogOpen && (
        <Dialog.Root open={isConfirmDialogOpen} onOpenChange={setConfirmDialogOpen}>
          <ConfirmDialog
            title="삭제"
            onConfirm={handleDeleteConfirm}
            description="댓글을 삭제하면 다시 복구할 수 없습니다. 삭제하시겠습니까?"
          />
        </Dialog.Root>
      )}
      {isDeleteSuccessDialogOpen && (
        <Dialog.Root open={isDeleteSuccessDialogOpen} onOpenChange={setDeleteSuccessDialogOpen}>
          <ConfirmDialog title="삭제 완료" description="댓글이 삭제되었습니다." showCancel={false} />
        </Dialog.Root>
      )}
    </form>
  );
}
