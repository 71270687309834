import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Flex, Section, Text, Dialog, Table, IconButton } from '@radix-ui/themes';
import { EyeOpenIcon } from '@radix-ui/react-icons';

import PageTitle from 'components/layout/PageTitle';
import { ConfirmDialog, TemplatePreviewDialog } from 'components/dialog';

import { instance } from 'api/axios.instance';

// 프로젝트 양식 관리 > 아젠다 관리 > 상세 (프로젝트 관리자, 웹 마스터 관리자 전용)
export default function AgendaDetail() {
  // hooks
  const location = useLocation();
  const navigate = useNavigate();
  const agendaCode = location.pathname.split('/agenda/detail/')[1];

  // state
  const [agenda, setAgenda] = useState(null); // 아젠다 상세
  const [isOpenPreviewDialog, setIsOpenPreviewDialog] = useState(false); // 템플릿 미리 보기 모달
  const [componentName, setComponentName] = useState(''); // 미리보기할 템플릿

  // 아젠다 상세 조회 API
  const fetchAgendaDetail = useCallback(async () => {
    const response = await instance(`/admin/agenda/${agendaCode}`, {
      method: 'GET'
    });

    setAgenda(response.data.data);
  }, [agendaCode]);

  // 아젠다 삭제 API
  const handleDeleteAgenda = async () => {
    const response = await instance('/admin/agenda/edit', {
      method: 'POST',
      data: {
        agendaCode,
        isDeleted: true
      }
    });

    if (response.data.status === 201) {
      navigate('/agenda');
    }
  };

  // mount 시 호출
  useEffect(() => {
    fetchAgendaDetail();
  }, [fetchAgendaDetail]);

  return (
    <>
      <Section>
        <PageTitle title="프로젝트 아젠다 상세" />

        <Box className="box box-white">
          <Table.Root className="table-write">
            <Table.Body>
              <Table.Row align="center">
                <Table.ColumnHeaderCell colSpan={2}>
                  <Text className="required-start">아젠다 명</Text>
                </Table.ColumnHeaderCell>
                <Table.Cell colSpan={10}>
                  <Text as="p" color="gray">
                    {agenda?.agendaName}
                  </Text>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
            <Table.Body>
              <Table.Row align="center">
                <Table.ColumnHeaderCell colSpan={2}>
                  <Text>사용여부</Text>
                </Table.ColumnHeaderCell>
                <Table.Cell colSpan={10}>
                  <Flex align="center">
                    <Text as="p" color="gray">
                      {agenda?.isUsed ? '사용' : '사용안함'}
                    </Text>
                  </Flex>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
            <Table.Body>
              <Table.Row align="center">
                <Table.ColumnHeaderCell colSpan={2}>
                  <Text className="required-start">사용 기간</Text>
                </Table.ColumnHeaderCell>
                <Table.Cell colSpan={10}>
                  <Text as="p" color="gray">
                    {agenda?.startDate} ~ {agenda?.endDate}
                  </Text>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table.Root>
        </Box>

        <Box className="box box-white">
          <Box className="basic-table">
            <Table.Root variant="surface" size="3">
              <caption>아젠다 관리</caption>
              <Table.Header>
                <Table.Row align="center">
                  <Table.ColumnHeaderCell justify="center" width="300px">
                    템플릿 명
                  </Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell justify="center" width="100px">
                    사용 여부
                  </Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell justify="center" width="auto">
                    단계 설명
                  </Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell justify="center" width="100px">
                    미리보기
                  </Table.ColumnHeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {agenda?.templates.map((el, i) => (
                  <Table.Row key={i} align="center">
                    <Table.Cell justify="center">{el.templateName}</Table.Cell>
                    <Table.Cell justify="center">
                      <Flex align="center" justify="center">
                        {el.isUsed ? '사용' : '사용안함'}
                      </Flex>
                    </Table.Cell>
                    <Table.Cell justify="center">
                      <Text color="gray">{el?.stageDesc}</Text>
                    </Table.Cell>
                    <Table.Cell justify="center">
                      <IconButton
                        onClick={() => {
                          setIsOpenPreviewDialog(true);
                          setComponentName(el.componentName);
                        }}
                        className="rt-variant-text"
                      >
                        <EyeOpenIcon width={24} height={24} />
                      </IconButton>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table.Root>
          </Box>
        </Box>

        <Box>
          <Flex gap="var(--space-2)" justify="end">
            <Link to={`/agenda/write/${agendaCode}`}>
              <Button size="2">수정</Button>
            </Link>

            <Dialog.Root>
              <Dialog.Trigger>
                <Button size="2">삭제</Button>
              </Dialog.Trigger>
              <ConfirmDialog title="아젠다 삭제" description="아젠다를 삭제 하시겠습니까?" onConfirm={handleDeleteAgenda} />
            </Dialog.Root>

            <Link to="/agenda">
              <Button size="2" variant="outline">
                취소
              </Button>
            </Link>
          </Flex>
        </Box>
      </Section>

      {isOpenPreviewDialog && (
        <Dialog.Root open={isOpenPreviewDialog} onOpenChange={setIsOpenPreviewDialog}>
          <TemplatePreviewDialog componentName={componentName} />
        </Dialog.Root>
      )}
    </>
  );
}
