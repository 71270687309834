import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, IconButton, TextField, Tooltip } from '@radix-ui/themes';
import { MagnifyingGlassIcon } from '@radix-ui/react-icons';
import { useSearchParams } from 'react-router-dom';

import { SelectBox } from 'components/form';
import { TableContext } from 'components/table/TableWrapper';

// 테이블 상단 검색 바
export default function TableSearchBox() {
  // hooks
  const { boardType, getSearchText } = useContext(TableContext);
  const [searchParams] = useSearchParams();
  const region = searchParams.get('region');

  // state
  const [searchText, setSearchText] = useState(searchParams.get('search') || ''); // 검색어
  const [selectedValue, setSelectedValue] = useState(); // 선택한 검색 카테고리
  const [selectOptions, setSelectOptions] = useState([]); // 검색 카테고리 목록
  const isFirstRender = useRef(true);

  // 검색 Method
  const handleSearch = () => {
    getSearchText({ text: searchText, type: selectedValue });
  };

  // mount 및 boardType 변경 시 검색 카테고리 업데이트
  useEffect(() => {
    if (['agenda', 'template', 'projectRequest', 'project', 'question'].includes(boardType)) {
      setSelectOptions(['제목']);
      setSelectedValue(searchParams.get('searchType') || '제목');
    } else if (['notice', 'promotion'].includes(boardType)) {
      setSelectOptions(['제목', '내용']);
      setSelectedValue(searchParams.get('searchType') || '제목');
    } else if (boardType === 'account') {
      setSelectOptions(['지역', '아이디']);
      setSelectedValue(searchParams.get('searchType') || '지역');
    } else if (boardType === 'agendaRequest') {
      setSelectOptions(['프로젝트명', '아젠다명']);
      setSelectedValue(searchParams.get('searchType') || '프로젝트명');
    }
  }, [boardType, searchParams]);

  // mount 및 지역 변경 시 검색어 초기화
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false; // 마운트 이후 플래그 설정
      return;
    }

    setSearchText(searchParams.get('search') || '');
  }, [region, searchParams]);

  return (
    <>
      <Box flexBasis="160px" flexShrink="0">
        <SelectBox
          size="2"
          placeholder="선택해 주세요."
          value={selectedValue}
          options={selectOptions}
          onChange={value => setSelectedValue(value)}
        />
      </Box>
      <Box flexBasis="252px" flexShrink="1">
        <TextField.Root
          placeholder="내용을 입력해 주세요."
          color="gray"
          title="내용 입력 창"
          value={searchText}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              handleSearch();
            }
          }}
          onChange={e => setSearchText(e.target.value)}
        />
      </Box>
      <IconButton className="rt-variant-text" onClick={handleSearch}>
        {searchText.length > 0 || searchText.length === null ? (
          <MagnifyingGlassIcon width={36} height={36} />
        ) : (
          <Tooltip content="검색어를 입력해 주세요.">
            <MagnifyingGlassIcon width={36} height={36} />
          </Tooltip>
        )}
      </IconButton>
    </>
  );
}
