import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Section, Box, Flex, TextField, Text, Button, Table } from '@radix-ui/themes';
import { useFormik } from 'formik';

import PageTitle from 'components/layout/PageTitle';

import { instance } from 'api/axios.instance';
import useRenderComponent from 'hooks/useRenderComponent';

export default function AgendaRequestDetail() {
  // hooks
  const { id } = useParams();
  const navigate = useNavigate();
  const { Components, handleRenderComponents } = useRenderComponent(); // 동적 컴포넌트 hook

  // state
  const [formValues, setFormValues] = useState({
    title: '', // 프로젝트 명,
    agendaName: '', // 아젠다 명
    agendaData: [] // 아젠다
  });

  // form 유효성 검사
  const formik = useFormik({
    initialValues: formValues,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true
  });

  // 아젠다 요청 상세 조회 API
  const fetchDocsById = useCallback(async () => {
    const response = await instance(`/docs/${id}`, {
      method: 'GET'
    });

    if (response.data.status === 200) {
      handleRenderComponents(response.data.data.agendaData?.map(data => data.componentName));
      setFormValues(prev => {
        return {
          ...prev,
          title: response.data.data.projectName,
          agendaName: response.data.data.agendaName,
          agendaData: response.data.data.agendaData.map(data => {
            return {
              templateCode: data.templateCode,
              templateData: data.templateData
            };
          })
        };
      });
    }
  }, [handleRenderComponents, id]);

  // mount 시 호출
  useEffect(() => {
    fetchDocsById();
  }, [fetchDocsById]);

  return (
    <Section className="template-form-wrap">
      <PageTitle title="아젠다 요청 상세" />
      <form onSubmit={formik.handleSubmit}>
        <Box className="box box-white">
          <Box>
            <Table.Root className="table-write">
              <Table.Body>
                <Table.Row align="center">
                  <Table.ColumnHeaderCell colSpan={2}>
                    <Text className="required-start">프로젝트 명</Text>
                  </Table.ColumnHeaderCell>
                  <Table.Cell colSpan={10}>
                    <Text as="label" htmlFor="title">
                      <TextField.Root size="2" id="title" name="title" defaultValue={formValues.title} readOnly />
                    </Text>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
              <Table.Body>
                <Table.Row align="center">
                  <Table.ColumnHeaderCell colSpan={2}>
                    <Text className="required-start">아젠다 명</Text>
                  </Table.ColumnHeaderCell>
                  <Table.Cell colSpan={10}>
                    <Text as="label" htmlFor="agendaName">
                      <TextField.Root size="2" id="agendaName" defaultValue={formValues.agendaName} readOnly />
                    </Text>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table.Root>
          </Box>

          {/* 아젠다 */}
          {Components.length > 0 && (
            <Suspense fallback={<div />}>
              {Components.map((Component, index) => (
                <Component key={index} index={index} formik={formik} setFormValues={setFormValues} readOnly />
              ))}
            </Suspense>
          )}
        </Box>

        <Box>
          <Flex gap="var(--space-2)" justify="end">
            <Button size="2" variant="outline" type="button" onClick={() => navigate('/agendaRequest')}>
              취소
            </Button>
          </Flex>
        </Box>
      </form>
    </Section>
  );
}
