import React from 'react';
import { Dialog } from '@radix-ui/themes';
import { TrashIcon } from '@radix-ui/react-icons';

import { ConfirmDialog } from 'components/dialog';

export function DeleteButton({ onDelete }) {
  return (
    <Dialog.Root>
      <Dialog.Trigger>
        <TrashIcon width={24} height={24} color="var(--blue-9)" style={{ cursor: 'pointer' }} />
      </Dialog.Trigger>
      <ConfirmDialog title="컨텐츠 삭제" description="해당 내용을 제거 하시겠습니까?" onConfirm={onDelete} />
    </Dialog.Root>
  );
}
