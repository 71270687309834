import React, { useContext, useEffect, useState } from 'react';
import { Button, Flex } from '@radix-ui/themes';
import { useSearchParams } from 'react-router-dom';

import { TableContext } from 'components/table/TableWrapper';

import { localList, regions } from 'app/constants';
import './sortByLocal.scss';

// 지역 이슈 관리 > 지역 선택 탭 (프로젝트 관리자, 웹 마스터 관리자 전용)
export default function SortByLocal() {
  // hooks
  const { setRegion } = useContext(TableContext);
  const [searchParams] = useSearchParams();
  const region = searchParams.get('region');

  // state
  const [active, setActive] = useState(null); // 활성화된 지역 인덱스

  // 지역 클릭 이벤트 Method
  const handleClick = i => {
    setActive(i);
    setRegion(regions[localList[i].name]);
  };

  // mount 시 searchParams에 맞는 지역 활성화
  useEffect(() => {
    const localIndex = localList.map(data => data.code).indexOf(region);
    localIndex >= 0 ? setActive(localIndex) : setActive(0);
  }, [region]);
  return (
    <Flex align="center" justify="between" mb="var(--space-10)" className="sort-by-local">
      {localList.map((el, i) => {
        return (
          <Button size="2" key={i} className={`body2 ${active === i ? 'active' : ''}`} onClick={() => handleClick(i)}>
            {el.name}
          </Button>
        );
      })}
    </Flex>
  );
}
