import { useCallback, useState } from 'react';

// 동적 컴포넌트 생성 hook
const useRenderComponent = () => {
  const [Components, setComponents] = useState([]); // 동적 컴포넌트 목록

  // 동적 컴포넌트 생성 함수
  const handleRenderComponents = useCallback(async componentNames => {
    for (const componentName of componentNames) {
      const { default: LoadedComponent } = await import(`components/template/${componentName}`);
      setComponents(prev => [...prev, LoadedComponent]);
    }
  }, []);

  // 컴포넌트 초기화 함수
  const resetComponents = useCallback(() => {
    setComponents([]);
  }, []);

  return {
    Components,
    handleRenderComponents,
    resetComponents
  };
};

export default useRenderComponent;
