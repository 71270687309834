import React from 'react';
import { Button, Dialog, IconButton, Flex, VisuallyHidden, Box, Text } from '@radix-ui/themes';
import { Cross1Icon } from '@radix-ui/react-icons';
import { useFormik } from 'formik';
import { Textarea } from '../form';
import { validationSchemaReject } from 'util/validationSchemas';
import './style.scss';

//  문의하기 팝업
export function CommentsDialog({ title, subTitle, onClick, status }) {
  const formik = useFormik({
    initialValues: {
      reason: ''
    },
    validationSchema: validationSchemaReject,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async values => {
      if (status === 'pending') {
        await onClick('processing', values.reason);
      } else if (status === 'processing') {
        await onClick('completed', values.reason);
      } else if ('completed') {
        await onClick('processing', values.reason);
      }
    }
  });

  return (
    <Dialog.Content maxWidth="400px" className="basic-dialog dialog-comments">
      <Dialog.Close>
        <IconButton className="rt-variant-text close-btn">
          <Cross1Icon />
        </IconButton>
      </Dialog.Close>
      <Dialog.Title>{title}</Dialog.Title>
      <VisuallyHidden>
        <Dialog.Description />
      </VisuallyHidden>
      {subTitle && (
        <Text color="gray" size="2" mt="var(--space-3)" mb="var(--space-5)">
          {subTitle}
        </Text>
      )}
      <form onSubmit={formik.handleSubmit}>
        <Box size="2" mt="var(--space-6)" mb="var(--space-7)">
          <>
            <Textarea
              size="3"
              required
              maxLength="300"
              name="reason"
              value={formik.values.reason}
              onChange={formik.handleChange}
              className=" height-md "
            />
            {formik.touched?.reason && formik.errors?.reason && <Text color="red">{formik.errors?.reason}</Text>}
          </>
        </Box>

        <Flex gap="var(--space-2)" justify="center">
          <Button size="2" className="btn-lg" type="submit">
            확인
          </Button>
          <Dialog.Close>
            <Button size="2" variant="outline">
              취소
            </Button>
          </Dialog.Close>
        </Flex>
      </form>
    </Dialog.Content>
  );
}
