export const regions = {
  전체: '',
  강원: 'GW',
  경기: 'GG',
  경남: 'GN',
  경북: 'GB',
  광주: 'GJ',
  대구: 'DG',
  울산: 'US',
  인천: 'IC',
  전남: 'JN',
  전북: 'JB',
  제주: 'JJ',
  충남: 'CN',
  충북: 'CB'
};

export const localList = [
  { name: '전체', code: '' },
  { name: '강원', code: 'GW' },
  { name: '경기', code: 'GG' },
  { name: '경남', code: 'GN' },
  { name: '경북', code: 'GB' },
  { name: '광주', code: 'GJ' },
  { name: '대구', code: 'DG' },
  { name: '울산', code: 'US' },
  { name: '인천', code: 'IC' },
  { name: '전남', code: 'JN' },
  { name: '전북', code: 'JB' },
  { name: '제주', code: 'JJ' },
  { name: '충남', code: 'CN' },
  { name: '충북', code: 'CB' }
];
