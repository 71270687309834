import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Text, Section, Flex, Button } from '@radix-ui/themes';

import './style.scss';

// 회원가입 완료
export default function MembershipComplete() {
  // hooks
  const navigate = useNavigate();

  return (
    <Section className="join-wrap">
      <Flex direction="column" gap="var(--space-5)" py="var(--space-20)">
        <Flex align="center" justify="start" gap="var(--space-3)">
          <Box className="logo">
            <img src={require('assets/images/common/logo.svg').default} alt="NipaDreams" />
          </Box>

          <Text as="p" className="title3">
            회원 가입
          </Text>
        </Flex>

        <Box py="var(--space-5)" mb="var(--space-5)" className="welcome-wrap">
          <Flex py="var(--space-17)" direction="column" align="center">
            <Text as="p" mb="var(--space-2)" className="welcome-title">
              DREAMS 회원 가입을 환영합니다.
            </Text>
            <Text as="p" mb="var(--space-2)" color="gray" className="body2">
              계정 관리자의 승인 시 이메일로 승인 메일이 발송됩니다.
            </Text>
            {/* <Button size="1" variant="outline" className="btn-lg">
                  문의하기
                </Button> */}
          </Flex>
        </Box>

        <Flex justify="end">
          <Button
            onClick={() => {
              navigate('/');
            }}
          >
            홈으로
          </Button>
        </Flex>
      </Flex>
    </Section>
  );
}
