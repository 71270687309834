import React, { useContext } from 'react';
import { Table } from '@radix-ui/themes';

import { TableContext } from 'components/table/TableWrapper';

// 테이블 헤더
export default function TableHeader() {
  const { tableHeader } = useContext(TableContext);

  return (
    <Table.Header>
      <Table.Row align="center">
        {tableHeader?.map((header, index) => {
          return (
            <Table.ColumnHeaderCell key={index} justify="center" width={header.width}>
              {header.label}
            </Table.ColumnHeaderCell>
          );
        })}
      </Table.Row>
    </Table.Header>
  );
}
