import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Section, Box, Text, Table, Flex, Button, Dialog } from '@radix-ui/themes';

import PageTitle from 'components/layout/PageTitle';
import { ConfirmDialog } from 'components/dialog';

import { instance } from 'api/axios.instance';

export default function LocalStatusDetail() {
  const navigate = useNavigate();
  const { id } = useParams();

  const tempData = {
    title: '사업제목 영역',
    year: '2023',
    region: '세종',
    content: '데이터 내용입니다.'
  };
  const [businessData, setBusinessData] = useState(tempData);

  const fetchBusinessDetail = useCallback(async () => {
    const response = await instance(`/business/${id}`, {
      method: 'GET'
    });

    setBusinessData(response.data.data);
  }, [id]);

  useEffect(() => {
    // fetchBusinessDetail();
  }, [fetchBusinessDetail]);

  return (
    <Section>
      <PageTitle title="지역별 사업 현황" />
      <Box className="box box-white">
        <Box mb="var(--space-10)">
          <Table.Root className="table-write">
            <Table.Body>
              <Table.Row align="center">
                <Table.ColumnHeaderCell width="180px">
                  <Text>제목</Text>
                </Table.ColumnHeaderCell>
                <Table.Cell width="auto">
                  <Text as="p" color="gray">
                    {businessData?.title}
                  </Text>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
            <Table.Body>
              <Table.Row align="center">
                <Table.ColumnHeaderCell width="180px">
                  <Text>데이터 년도</Text>
                </Table.ColumnHeaderCell>
                <Table.Cell width="auto">
                  <Text as="p" color="gray">
                    {businessData?.year}
                  </Text>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
            <Table.Body>
              <Table.Row align="center">
                <Table.ColumnHeaderCell width="180px">
                  <Text>지역</Text>
                </Table.ColumnHeaderCell>
                <Table.Cell width="auto">
                  <Text as="p" color="gray">
                    {businessData?.region}
                  </Text>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table.Root>
        </Box>

        <Box>
          <Text as="p" className="sub-title1">
            데이터
          </Text>
          <Box mt="var(--space-1)">
            <Text as="p" color="gray">
              {businessData?.content}
            </Text>
          </Box>
        </Box>
      </Box>

      {/* 페이지 버튼 */}
      <Box>
        <Flex justify="between">
          <Button
            variant="outline"
            onClick={() => {
              navigate('/localStatus');
            }}
          >
            목록
          </Button>
          <Flex justify="end" gap="2">
            <Button onClick={() => navigate(`/localStatus/write/${id}`)}>수정</Button>
            <Dialog.Root>
              <Dialog.Trigger>
                <Button>삭제</Button>
              </Dialog.Trigger>
              <ConfirmDialog title="컨텐츠 삭제" description="해당 내용을 제거 하시겠습니까?" onConfirm={() => {}} />
            </Dialog.Root>
          </Flex>
        </Flex>
      </Box>
    </Section>
  );
}
