import {
  DecoupledEditor,
  AccessibilityHelp,
  Alignment,
  Autoformat,
  AutoImage,
  AutoLink,
  Autosave,
  Bold,
  Essentials,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  Heading,
  HorizontalLine,
  HtmlEmbed,
  ImageBlock,
  ImageCaption,
  ImageInline,
  ImageInsertViaUrl,
  ImageResize,
  ImageStyle,
  ImageTextAlternative,
  ImageToolbar,
  ImageUpload,
  Indent,
  Italic,
  Link,
  Paragraph,
  RemoveFormat,
  SpecialCharacters,
  Strikethrough,
  Subscript,
  Superscript,
  Table,
  TextTransformation,
  Underline,
  Undo,
  MediaEmbed,
  MediaEmbedToolbar
} from 'ckeditor5';
import MyCustomUploadAdapterPlugin from 'util/plugin/customUploadAdapter';

export const editorConfig = {
  editor: DecoupledEditor,
  config: {
    toolbar: {
      items: [
        'undo',
        'redo',
        '|',
        'heading',
        '|',
        'fontSize',
        'fontFamily',
        'fontColor',
        'fontBackgroundColor',
        '|',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        '|',
        'specialCharacters',
        'horizontalLine',
        'link',
        'insertImage',
        'insertTable',
        'mediaEmbed',
        '|',
        'alignment',
        '|',
        'outdent',
        'indent'
      ],
      shouldNotGroupWhenFull: true
    },
    plugins: [
      AccessibilityHelp,
      Alignment,
      Autoformat,
      AutoImage,
      AutoLink,
      Autosave,
      Bold,
      MediaEmbed, // 플러그인 추가
      MediaEmbedToolbar, // 미디어 툴바 추가
      Essentials,
      FindAndReplace,
      FontBackgroundColor,
      FontColor,
      FontFamily,
      FontSize,
      Heading,
      HorizontalLine,
      HtmlEmbed,
      ImageBlock,
      ImageCaption,
      ImageInline,
      ImageInsertViaUrl,
      ImageResize,
      ImageStyle,
      ImageTextAlternative,
      ImageToolbar,
      ImageUpload,
      Indent,
      Italic,
      Link,
      Paragraph,
      RemoveFormat,
      SpecialCharacters,
      Strikethrough,
      Subscript,
      Superscript,
      Table,
      TextTransformation,
      Underline,
      Undo
    ],
    extraPlugins: [MyCustomUploadAdapterPlugin],
    image: {
      toolbar: [
        'toggleImageCaption',
        'imageTextAlternative',
        '|',
        'imageStyle:inline',
        'imageStyle:wrapText',
        'imageStyle:breakText',
        '|',
        'resizeImage'
      ]
    },
    fontFamily: {
      supportAllValues: true
    },
    fontSize: {
      options: [10, 12, 14, 'default', 18, 20, 22],
      supportAllValues: true
    },
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: 'https://',
      decorators: {
        toggleDownloadable: {
          mode: 'manual',
          label: 'Downloadable',
          attributes: {
            download: 'file'
          }
        }
      }
    },
    placeholder: '',
    table: {
      contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
    },

    mediaEmbed: {
      toolbar: ['mediaEmbed'],
      previewsInData: true
    }
  }
};
