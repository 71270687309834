import React, { useState } from 'react';
import { TextField, Tooltip, Text, Box } from '@radix-ui/themes';
import classNames from 'classnames';

export function Password(props) {
  const [showPassword, setShowPassword] = useState(false);

  const toggleVisibility = () => {
    setShowPassword(prev => !prev);
  };

  return (
    <fieldset className={classNames({ 'flag-error': props.error })}>
      <TextField.Root
        size={props.size}
        type={showPassword ? 'text' : 'password'}
        placeholder={props.placeholder}
        autoComplete="current-password"
        onChange={e => {
          props.handlePassword(e.target.value);
        }}
        className="password"
        id={props.id}
      >
        {!props.error && (
          <TextField.Slot side="right" value={props.password}>
            <Tooltip content={showPassword ? '비밀번호 숨기기' : '비밀번호 보기'}>
              <button type="button" onClick={toggleVisibility} className={showPassword ? 'active' : ''} />
            </Tooltip>
          </TextField.Slot>
        )}
        {props.error && (
          // 에러일 때만 아이콘 표시
          <TextField.Slot side="right">
            <Box className="icon-error">
              <img src={require('assets/images/icon/icon-error.svg').default} alt="에러아이콘" />
            </Box>
          </TextField.Slot>
        )}
      </TextField.Root>
      {props.error && (
        <Text as="p" className="caption text-error">
          아이디(로그인 전용 아이디) 또는 비밀번호를 잘못 입력했습니다. <br />
          입력하신 내용을 다시 확인해주세요.
        </Text>
      )}
    </fieldset>
  );
}
