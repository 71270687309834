import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Section, Box, Text, Table, TextField, Flex, Button } from '@radix-ui/themes';
import { Textarea, SelectBox } from 'components/form';
import PageTitle from 'components/layout/PageTitle';

export default function LocalStatusWrite() {
  const navigate = useNavigate();
  const location = useLocation();

  const [selectedValueYear, setSelectedValueYear] = useState('');
  const [selectedValueRegion, setSelectedValueRegion] = useState('');

  const businessCode = location.pathname.split('/localStatus/write/')[1];
  const [isEditMode, setIsEditMode] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  // const createBusiness = useCallback(async () => {
  //   const response = await instance('/business/create', {
  //     method: 'GET'
  //   });
  // }, []);

  // const editBusiness = useCallback(async () => {
  //   const response = await instance('/business/edit', {
  //     method: 'GET'
  //   });
  // }, []);

  useEffect(() => {
    businessCode ? setIsEditMode(true) : setIsEditMode(false);
  }, [businessCode]);

  return (
    <Section>
      <PageTitle title="지역별 사업 현황 등록" />
      <Box className="box box-white">
        <Box mb="var(--space-10)">
          <Table.Root className="table-write">
            <Table.Body>
              <Table.Row align="center">
                <Table.ColumnHeaderCell colSpan={2}>
                  <Text className="required-start">제목</Text>
                </Table.ColumnHeaderCell>
                <Table.Cell colSpan={10}>
                  <Text as="label" htmlFor="title">
                    <TextField.Root
                      size="2"
                      id="title"
                      placeholder="제목을 선택해 주세요."
                      value={title}
                      onChange={value => setTitle(value)}
                    />
                  </Text>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
            <Table.Body>
              <Table.Row align="center">
                <Table.ColumnHeaderCell colSpan={2}>
                  <Text className="required-start">데이터 년도</Text>
                </Table.ColumnHeaderCell>
                <Table.Cell colSpan={10}>
                  <SelectBox
                    size="2"
                    placeholder="데이터 년도를 선택해 주세요."
                    value={selectedValueYear}
                    options={['2020년', '2021년', '2022년', '2023년', '2024년']}
                    onChange={value => setSelectedValueYear(value)}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
            <Table.Body>
              <Table.Row align="center">
                <Table.ColumnHeaderCell colSpan={2}>
                  <Text className="required-start">지역</Text>
                </Table.ColumnHeaderCell>
                <Table.Cell colSpan={10}>
                  <SelectBox
                    size="2"
                    placeholder="지역을 선택해 주세요."
                    value={selectedValueRegion}
                    options={['서울', '대전', '세종']}
                    onChange={value => setSelectedValueRegion(value)}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table.Root>
        </Box>

        <Box>
          <Text as="p" className="sub-title1">
            데이터
          </Text>
          <Text color="gray" mt="var(--space-1)" className="body3">
            * 지역에서 해결하고 싶은 현안 또는 지역 내부에서 논의되고 있는 현안
          </Text>
          <Box mt="var(--space-4)">
            <Textarea
              size="3"
              maxLength="1000"
              placeholder="강원도 고령 인구 비중은 지난해로 높은 수치를 보임 노인 비중 증가 폭 전국 1위 현재 춘천 지역의 경우 봄봄버스라는 마을버스
운행 중에 있음"
              className="height-md"
              value={content}
              onChange={value => setContent(value)}
            />
          </Box>
        </Box>
      </Box>

      {/* 페이지 버튼 */}
      <Box>
        <Flex justify="end" gap="2">
          <Button
            variant="outline"
            onClick={() => {
              navigate('/localStatus');
            }}
          >
            취소
          </Button>
          {isEditMode ? <Button>저장</Button> : <Button>등록</Button>}
        </Flex>
      </Box>
    </Section>
  );
}
