import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { Box, Text, Section, Flex, Button, TextField } from '@radix-ui/themes';

import { CheckBox, SelectBox } from 'components/form';

import { confirmAuthEmailCode, registerUser, sendAuthEmailUser } from 'store/slices/users';
import { validationRegister } from 'util/validationSchemas';
import './style.scss';

const regionOptions = [
  { label: '강원', value: 'GW' },
  { label: '경기', value: 'GG' },
  { label: '경남', value: 'GN' },
  { label: '경북', value: 'GB' },
  { label: '경주', value: 'GJ' },
  { label: '대구', value: 'DG' },
  { label: '울산', value: 'US' },
  { label: '인천', value: 'IC' },
  { label: '전남', value: 'JN' },
  { label: '전북', value: 'JB' },
  { label: '제주', value: 'JJ' },
  { label: '충남', value: 'CN' },
  { label: '충북', value: 'CB' }
];

// 회원 가입
export default function Membership() {
  // hooks
  const navigate = useNavigate();
  const formikRef = useRef();

  // state
  const [isSend, setIsSend] = useState(false); // 이메일 인증번호 발송상태
  const [isSendMassage, setIsSendMassage] = useState(false); // 이메일 발송 메시지 여부

  const [authCodeError, setAuthCodeError] = useState(''); // 인증 번호 에러 메시지
  const [isConfirm, setIsConfirm] = useState(false); // 인증번호 확인 여부
  const [isVerified, setIsVerified] = useState(false); // 마지막 인증 되었는지 여부

  // 이용약관
  const [allAgree, setAllAgree] = useState(false);
  const [termsOfService, setTermsOfService] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);

  // 회원가입 API
  const handleSubmit = async values => {
    const result = await registerUser({ region: values.region, email: values.email });
    if (result.data.status === 201) {
      navigate('/membershipComplete');
    } else if (result.status === 409) {
      // TODO: 중복된 이메일 팝업 추가 필요
      alert('중복된 이메일입니다.');
    } else {
    }
  };

  // 이메일 인증번호 API
  const handleSendAuthEmailUser = async (event, region, email) => {
    event.preventDefault();

    const formik = formikRef.current;
    const { validateForm, setTouched, setErrors, setFieldValue } = formik;
    const errors = await validateForm();
    setFieldValue('authCode', '');

    setTouched({
      region: true,
      email: true
    });
    if (Object.keys(errors).length > 0) {
      // 유효성 검사 오류가 있는 경우
      return;
    }
    try {
      await validationRegister.validate({ region, email });
      setIsConfirm(false);
      setAuthCodeError('');
      const result = await sendAuthEmailUser(email);
      if (result.status === 200) {
        setIsSend(true);
        setIsSendMassage(true);
      } else {
        setIsSend(false);
        setIsSendMassage(false);
        const errorMessage = '인증 이메일 발송 중 오류가 발생했습니다.';
        setErrors({
          ...errors,
          email: errorMessage
        });
      }
    } catch (error) {
      const errorMessage = error.message || '인증 이메일 발송 중 오류가 발생했습니다.';
      // 이메일 필드의 에러 메시지 설정
      setErrors({
        ...errors,
        email: errorMessage
      });
      setAuthCodeError(error.message);
      setIsSend(false);
      setIsSendMassage(false);
    }
  };

  // 인증번호 확인 API
  const handleConfirmAuthCode = async (event, email, authCode) => {
    event.preventDefault();
    try {
      // await validationAuthCode.validate(authCode); // 객체로 유효성 검사
      setAuthCodeError('');
      setIsConfirm(false);
      setIsSendMassage(false);

      if (authCode === '' || authCode === null) {
        setAuthCodeError('인증 번호를 입력해주세요.');
        return;
      }

      const result = await confirmAuthEmailCode({ email, authNumber: authCode });
      if (result.status === 200) {
        setAuthCodeError('');
        setIsVerified(true);
        setIsConfirm(true);
      } else if (result.status === 401) {
        if (isVerified) {
          setIsVerified(false);
        }
        setAuthCodeError('인증 번호가 올바르지 않습니다.');
      }
    } catch (error) {
      setAuthCodeError(error.message);
      setIsSend(false);
    }
  };

  // 모두 동의 체크박스
  const handleAllAgreeChange = e => {
    const isChecked = typeof e === 'boolean' ? e : e.target.checked;
    setAllAgree(isChecked);
    setTermsOfService(isChecked);
    setPrivacyPolicy(isChecked);
  };

  // 개별 체크박스 핸들러
  const handleIndividualCheckboxChange = setter => e => {
    const isChecked = typeof e === 'boolean' ? e : e.target.checked;
    setter(isChecked);
    if (isChecked && termsOfService && privacyPolicy) {
      setAllAgree(true);
    } else {
      setAllAgree(false);
    }
  };

  const isConfirmEnabled = termsOfService && privacyPolicy;

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        region: '',
        email: '',
        authCode: '',
        allAgree: false,
        termsOfService: false,
        privacyPolicy: false
      }}
      validationSchema={validationRegister}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, handleChange, values, setFieldValue }) => (
        <Form>
          <Section className="join-wrap">
            <Flex direction="column" gap="var(--space-5)" py="var(--space-20)">
              <Flex align="center" justify="start" gap="var(--space-3)">
                <Box className="logo">
                  <img src={require('assets/images/common/logo.svg').default} alt="NipaDreams" />
                </Box>

                <Text as="p" className="title3">
                  회원 가입
                </Text>
              </Flex>

              <Box pt="var(--space-5)" pb="var(--space-5)" mb="var(--space-5)" className="request-wrap">
                <Flex align="center" mb="var(--space-3)" gap="var(--space-2)" className="request-bar">
                  <Text className="sub-title2 required-start">신청 지역</Text>
                  <Field name="region">
                    {({ field }) => (
                      <>
                        <div>
                          <SelectBox
                            size="2"
                            placeholder="지역을 선택해 주세요."
                            value={field.value}
                            onChange={value => setFieldValue('region', value)} // onChange에 함수 전달
                            options={regionOptions}
                            className={errors.region && touched.region ? 'error-border' : ''}
                          />
                        </div>
                        {errors.region && touched.region && (
                          <Flex align="start" gap="var(--space-1)" className="state-msg region">
                            <Box pt="2px">
                              <img src={require('assets/images/icon/icon-error.svg').default} alt="에러아이콘" />
                            </Box>
                            <Text as="span" className="body3 font-error">
                              {/* 필수 입력 사항입니다. */}
                              {errors.region}
                            </Text>
                          </Flex>
                        )}
                      </>
                    )}
                  </Field>
                </Flex>
                <Flex align="center" gap="var(--space-2)" className="request-bar">
                  <Text className="sub-title2 required-start">신청자 이메일</Text>
                  <Flex align="center" gap="var(--space-1)" className="email-bar">
                    <Field
                      as={TextField.Root}
                      placeholder="이메일을 입력해 주세요."
                      name="email"
                      className={errors.email && touched.email ? 'error-state' : ''}
                      disabled={isVerified}
                      onChange={e => {
                        handleChange(e);
                        setIsSend(false); // 이메일 변경 시 발송 상태 초기화
                        setIsSendMassage(false); // 이메일 변경 시 메시지 숨김
                        setFieldValue('authCode', '');
                      }}
                    />
                    <Button
                      type="button"
                      size="1"
                      className="btn-xlg"
                      onClick={event => handleSendAuthEmailUser(event, values.region, values.email)}
                      disabled={isVerified}
                    >
                      {isSend ? '다시 인증 이메일 보내기' : '인증번호 보내기'}
                    </Button>
                  </Flex>
                </Flex>
                {/* 신청자 이메일 에러메세지 */}
                {errors.email && touched.email && (
                  <Flex align="start" gap="var(--space-1)" mt="var(--space-1)" className="state-msg">
                    <Box>
                      <img src={require('assets/images/icon/icon-error.svg').default} alt="에러아이콘" />
                    </Box>
                    <Text as="span" className="body3 font-error">
                      {/* 필수 입력 사항입니다. */}
                      {errors.email}
                    </Text>
                  </Flex>
                )}
                {/* 이메일 발송 메세지 및 인증번호 입력란 : 인증번호 발송 후 띄워주세요. */}
                {isSend && !errors.email && (
                  <Flex direction="column" gap="var(--space-5)" mt="var(--space-1)" className="state-msg">
                    {isSendMassage && (
                      <Text as="span" className="body3 font-info">
                        인증 이메일이 발송되었습니다. 이메일을 확인하고 인증 번호를 입력하세요.
                      </Text>
                    )}
                    {!isConfirm && (
                      <Flex gap="var(--space-1)">
                        <Field name="authCode">
                          {({ field }) => (
                            <TextField.Root {...field} placeholder="인증번호 입력" className="auth-bar" disabled={isVerified} />
                          )}
                        </Field>
                        <Button
                          size="1"
                          variant="outline"
                          type="button"
                          onClick={e => handleConfirmAuthCode(e, values.email, values.authCode)}
                          disabled={isVerified}
                        >
                          확인
                        </Button>
                        {authCodeError && (
                          <Flex ml="var(--space-2)" align="center" gap="var(--space-1)">
                            <Box pt="var(--space-1)">
                              <img src={require('assets/images/icon/icon-error.svg').default} alt="에러아이콘" />
                            </Box>
                            <Text as="span" className="body3 font-error">
                              {/* 인증 번호가 올바르지 않습니다. */}
                              {authCodeError}
                            </Text>
                          </Flex>
                        )}
                      </Flex>
                    )}
                  </Flex>
                )}
                {/* 완료메세지 : 인증 완료 시 띄워주세요. */}
                {isConfirm && (
                  <Text as="p" mt="var(--space-1)" className="body3 font-info state-msg">
                    이메일 인증이 완료되었습니다!
                  </Text>
                )}
              </Box>
              {/* 이용약관 */}
              <Box className="term-wrap">
                <Flex align="center" justify="end" pb="var(--space-2)" className="all-check">
                  <label htmlFor="allAgree" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                    <CheckBox id="allAgree" checked={allAgree} onCheckedChange={handleAllAgreeChange} />
                    <Text as="p" color="gray" className="body2">
                      아래 내용에 모두 동의합니다.
                    </Text>
                  </label>
                </Flex>

                <Box mt="var(--space-5)">
                  <Flex align="center" mb="var(--space-3)">
                    <CheckBox checked={termsOfService} onCheckedChange={handleIndividualCheckboxChange(setTermsOfService)} />
                    <Text as="p" className="sub-title2">
                      [필수] 이용약관 동의
                    </Text>
                  </Flex>
                  <Flex direction="column" gap="var(--space-4)" className="term-box">
                    <Text as="p" className="sub-title2">
                      제 1 조 [목적]
                    </Text>
                    <Text as="p" color="gray" className="body3">
                      이 약관은 *** (이하 "회사")과 브랜드가 제공하는 개별 아이디(ID)를 하나의 ID로 사용할 수 있는 ONE ID 회원(이하
                      "회원")이 "*** ONE ID 서비스"(이하 "서비스")를 이용하는데 "회사"와 "이용자" 간의 권리 의무, 책임 사항, 이용 조건 및
                      절차 등 기본적인 사항을 규정함을 목적으로 합니다.
                    </Text>
                  </Flex>
                </Box>

                <Box mt="var(--space-6)">
                  <Flex align="center" mb="var(--space-3)">
                    <CheckBox checked={privacyPolicy} onCheckedChange={handleIndividualCheckboxChange(setPrivacyPolicy)} />
                    <Text as="p" className="sub-title2">
                      [필수] 개인 정보 수집 및 이용 동의
                    </Text>
                  </Flex>
                  <Flex direction="column" gap="var(--space-4)" className="term-box">
                    <Text as="p" className="sub-title2">
                      제 1 조 [목적]
                    </Text>
                    <Text as="p" color="gray" className="body3">
                      이 약관은 *** (이하 "회사")과 브랜드가 제공하는 개별 아이디(ID)를 하나의 ID로 사용할 수 있는 ONE ID 회원(이하
                      "회원")이 "*** ONE ID 서비스"(이하 "서비스")를 이용하는데 "회사"와 "이용자" 간의 권리 의무, 책임 사항, 이용 조건 및
                      절차 등 기본적인 사항을 규정함을 목적으로 합니다.
                    </Text>
                  </Flex>
                </Box>
              </Box>
              <Flex justify="end" gap="var(--space-2)">
                <Button
                  variant="outline"
                  onClick={() => {
                    navigate('/login');
                  }}
                >
                  취소
                </Button>
                <Button type="submit" disabled={!(isConfirmEnabled && isConfirm)}>
                  확인
                </Button>
              </Flex>
            </Flex>
          </Section>
        </Form>
      )}
    </Formik>
  );
}
