import { useState, useEffect, useRef } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import { editorConfig } from './editorConfig';
import 'ckeditor5/ckeditor5.css';
import './style.scss';

// CKEditor 컴포넌트
export default function Editor({ board, editorData, setEditorData, readOnly = false }) {
  // state
  const editorContainerRef = useRef(null);
  const editorToolbarRef = useRef(null);
  const editorRef = useRef(null);
  const [isLayoutReady, setIsLayoutReady] = useState(false);

  useEffect(() => {
    setIsLayoutReady(true);

    return () => {
      if (editorRef.current?.destroy) {
        editorRef.current.destroy();
        editorRef.current = null;
      }
      setIsLayoutReady(false);
    };
  }, []);

  return (
    <div>
      <div className="main-container">
        <div className="editor-container editor-container_document-editor editor-container_include-minimap" ref={editorContainerRef}>
          {!readOnly && <div className="editor-container__toolbar" ref={editorToolbarRef} />}
          <div className="editor-container__minimap-wrapper">
            <div className={`editor-container__editor-wrapper ${readOnly && 'read-only'}`}>
              <div ref={editorRef} className="editor-container__editor">
                {isLayoutReady && (
                  <CKEditor
                    editor={editorConfig.editor}
                    config={{ ...editorConfig.config, board }}
                    data={editorData}
                    onReady={editor => {
                      if (readOnly) {
                        editorRef.current = editor;
                        editor.enableReadOnlyMode('.main-container');
                        return;
                      }
                      editorToolbarRef.current.appendChild(editor.ui.view.toolbar.element);
                    }}
                    onChange={(event, editor) => {
                      if (readOnly) return;
                      const data = editor.getData();
                      setEditorData(data);
                    }}
                    onDestroy={() => {
                      editorRef.current = null;
                    }}
                    onAfterDestroy={() => {
                      if (editorToolbarRef.current) {
                        Array.from(editorToolbarRef.current.children).forEach(child => child.remove());
                      }
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
