import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Section, Box, Flex, TextField, Text, Button, Table, Dialog, Separator, RadioGroup } from '@radix-ui/themes';
import { useFormik } from 'formik';

import PageTitle from 'components/layout/PageTitle';
import { ConfirmDialog } from 'components/dialog';
import { FileUpload, Textarea, DeleteButton } from 'components/form';

import { instance } from 'api/axios.instance';
import { validationSchemaProject } from 'util/validationSchemas';
import useRenderComponent from 'hooks/useRenderComponent';

// 지역 이슈 관리 > 신규 등록 목록 > 등록/수정 (지역 이슈 담당자 전용)
export default function ProjectRequestWrite() {
  // hooks
  const { id } = useParams();
  const navigate = useNavigate();
  const { Components, handleRenderComponents, resetComponents } = useRenderComponent(); // 동적 컴포넌트 hook

  // state
  const [formValues, setFormValues] = useState({
    title: '', // 프로젝트 명
    description: '', // 설명
    source: '', // 출처
    thumbnail: null, // 썸네일
    agendaData: [] // 아젠다
  });
  const [isEditMode, setIsEditMode] = useState(false); // 수정 모드
  const [validationSchema, setValidationSchema] = useState(null); // 유효성 검사 스키마
  const [alias, setAlias] = useState('2'); // 별칭 아젠다 (지역현안, 아이디어, 프로젝트)
  const [isOpenSaveDialog, setIsOpenSaveDialog] = useState(false); // 프로젝트 저장 모달

  // form 유효성 검사
  const formik = useFormik({
    initialValues: formValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: values => {
      if (!values.thumbnail) {
        return formik.setFieldError('thumbnail', '썸네일은 필수 입력 항목입니다.');
      }
      setIsOpenSaveDialog(true);
      setFormValues(values);
    }
  });

  // 별칭 아젠다 선택 Method (지역현안 | 아이디어 | 프로젝트)
  const handleAlias = alias => {
    setAlias(alias);
    setFormValues(prev => {
      return { title: prev.title, agendaData: [] };
    });
    fetchAgendaByAlias(alias);
  };

  // 썸네일 파일 첨부 Method
  const handleFileChange = file => {
    const size = file.size > 50 * 1024 * 1024;

    if (size) {
      return formik.setFieldError('thumbnail', '파일은 50MB를 넘을 수 없습니다.');
    }
    formik.setFieldValue('thumbnail', file);
  };

  // 프로젝트 생성 API
  const handleCreateProject = async () => {
    const type = isEditMode ? 'edit' : 'create';
    const formData = new FormData();

    formValues.agendaData.forEach(data => {
      if (data.templateData.attachFiles) {
        data.templateData.attachFiles.forEach(file => {
          if (file.name) formData.append('attachFiles', file);
        });
      }
    });

    if (formValues.thumbnail.name) {
      formData.append('thumbnail', formValues.thumbnail);
    }

    formData.append(
      'body',
      JSON.stringify({
        alias: `step0${alias}-aaaaa`,
        stage: `${alias}단계`,
        id: isEditMode ? id : null,
        ...formValues
      })
    );

    const response = await instance(`/project/${type}`, {
      method: 'POST',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    if (response.data.status === 201) {
      navigate('/projectRequest');
    }
  };

  // 프로젝트 상세 조회 API
  const fetchAgendaById = useCallback(async () => {
    const response = await instance(`/project/${id}`, {
      method: 'GET'
    });

    if (response.status === 200) {
      resetComponents();
      handleRenderComponents(response.data.data.data.map(data => data.componentName));
      setAlias(response.data.data.stage.split('단계')[0]);
      setFormValues({
        title: response.data.data.title || '',
        description: response.data.data.description || '',
        source: response.data.data.source || '',
        alias: response.data.data.alias,
        stage: `${response.data.data.stage.split('단계')[0]}단계`,
        thumbnail: response.data.data.thumbnail,
        agendaData: response.data.data.data.map(data => {
          return {
            templateCode: data.templateCode,
            templateData: data.templateData
          };
        })
      });
    }
  }, [handleRenderComponents, id, resetComponents]);

  // 별칭 아젠다 조회 API (지역현안 | 아이디어 | 프로젝트) - 현재는 아이디어 아젠다만 존재
  const fetchAgendaByAlias = useCallback(
    async alias => {
      const response = await instance(`/agenda/alias/step0${alias}-aaaaa`, {
        method: 'GET'
      });
      resetComponents();

      handleRenderComponents(response.data.data.agendaData.map(data => data.componentName));
    },
    [handleRenderComponents, resetComponents]
  );

  // mount 시 param id 여부에 따라 호출
  useEffect(() => {
    if (id) {
      setIsEditMode(true);
      fetchAgendaById();
    } else {
      setIsEditMode(false);
      fetchAgendaByAlias(alias);
    }
  }, [alias, fetchAgendaByAlias, fetchAgendaById, id]);

  // mount 시 유효성 검사 동적 스키마 적용 (참여기관 및 주최, 제안자 인적 사항 템플릿 포함 여부에 따라 스키마를 동적으로 적용)
  useEffect(() => {
    setValidationSchema(
      validationSchemaProject({
        personalInfoIndex: formValues.agendaData.findIndex(data => data.templateCode === 'TP011'),
        organizationIndex: formValues.agendaData.findIndex(data => data.templateCode === 'TP014')
      })
    );
  }, [formValues]);

  return (
    <Section className="template-form-wrap">
      <PageTitle title={`프로젝트 ${isEditMode ? '수정' : '등록'}`} />
      <form onSubmit={formik.handleSubmit}>
        <Box className="box box-white">
          <Box mb="var(--space-10)">
            <Table.Root className="table-write">
              <Table.Body>
                <Table.Row align="center">
                  <Table.ColumnHeaderCell width="12%">
                    <Text className="required-start">프로젝트 명</Text>
                  </Table.ColumnHeaderCell>
                  <Table.Cell width="auto">
                    <Text as="label" htmlFor="title">
                      <TextField.Root
                        size="2"
                        id="title"
                        required
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Text>
                    {formik.touched.title && formik.errors.title && (
                      <Text as="p" mt="var(--space-1)" className="font-error body3">
                        {formik.errors.title}
                      </Text>
                    )}
                  </Table.Cell>
                </Table.Row>
                <Table.Row align="center">
                  <Table.ColumnHeaderCell width="12%">
                    <Text className="required-start">설명</Text>
                  </Table.ColumnHeaderCell>
                  <Table.Cell width="auto">
                    <Text as="label" htmlFor="description">
                      <Textarea
                        size="2"
                        id="description"
                        required
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Text>
                    {formik.touched.description && formik.errors.description && (
                      <Text as="p" mt="var(--space-1)" className="font-error body3">
                        {formik.errors.description}
                      </Text>
                    )}
                  </Table.Cell>
                </Table.Row>
                <Table.Row align="center">
                  <Table.ColumnHeaderCell width="12%">
                    <Text className="required-start">출처</Text>
                  </Table.ColumnHeaderCell>
                  <Table.Cell width="auto">
                    <Text as="label" htmlFor="source">
                      <TextField.Root
                        size="2"
                        id="source"
                        required
                        value={formik.values.source}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </Text>
                    {formik.touched.source && formik.errors.source && (
                      <Text as="p" mt="var(--space-1)" className="font-error body3">
                        {formik.errors.source}
                      </Text>
                    )}
                  </Table.Cell>
                </Table.Row>
                <Table.Row align="center">
                  <Table.ColumnHeaderCell width="12%">
                    <Text className="required-start">썸네일 첨부</Text>
                  </Table.ColumnHeaderCell>
                  <Table.Cell width="full">
                    <Flex direction="column" align="start" px="var(--space-1)">
                      <FileUpload
                        label="파일선택"
                        id="thumbnail-upload"
                        accept="image/*"
                        message="IMG 파일, 개당 50MB"
                        multiple={false}
                        onFileChange={handleFileChange}
                      />
                    </Flex>
                    {formik.values.thumbnail && (
                      <Box mt="2">
                        <img
                          src={formik.values.thumbnail.fileUrl || URL.createObjectURL(formik.values.thumbnail)}
                          alt="썸네일 미리보기"
                          style={{ width: '150px', height: '212px' }}
                        />
                        <Box className="file-item" mt="2">
                          <Text as="p" color="gray">
                            {formik.values.thumbnail.originalName || formik.values.thumbnail.fileName}
                          </Text>
                          <DeleteButton onDelete={() => formik.setFieldValue('thumbnail', null)} />
                        </Box>
                      </Box>
                    )}
                    {formik.touched.thumbnail && formik.errors.thumbnail && (
                      <Text as="p" mt="var(--space-1)" className="font-error body3">
                        {formik.errors.thumbnail}
                      </Text>
                    )}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table.Root>
          </Box>

          <Box mb="var(--space-10)">
            <RadioGroup.Root size="3" color="blue" value={alias} onValueChange={handleAlias}>
              <Flex align="center" gap="var(--space-7)">
                <Text as="label" className="sub-title1">
                  <Flex align="center">
                    <RadioGroup.Item disabled value="1" /> 지역현안
                  </Flex>
                </Text>
                <Text as="label" className="sub-title1">
                  <Flex align="center">
                    <RadioGroup.Item value="2" /> 아이디어
                  </Flex>
                </Text>
                <Text as="label" className="sub-title1">
                  <Flex align="center">
                    <RadioGroup.Item disabled value="3" /> 프로젝트
                  </Flex>
                </Text>
              </Flex>
            </RadioGroup.Root>
          </Box>

          <Separator size="4" mb="var(--space-5)" />

          {/* 아젠다 */}
          {Components.length > 0 && (
            <Suspense fallback={<div />}>
              {Components.map((Component, index) => (
                <Component key={index} index={index} formik={formik} setFormValues={setFormValues} isWriteMode={!isEditMode} />
              ))}
            </Suspense>
          )}
        </Box>

        <Box>
          <Flex gap="var(--space-2)" justify="end">
            <Dialog.Root>
              <Dialog.Trigger>
                <Button size="2" variant="outline">
                  취소
                </Button>
              </Dialog.Trigger>
              <ConfirmDialog
                title="저장 취소"
                description="작성중인 내용을 저장하지 않고 나가시겠습니까?"
                onConfirm={() => navigate('/projectRequest')}
              />
            </Dialog.Root>

            <Dialog.Root open={isOpenSaveDialog} onOpenChange={setIsOpenSaveDialog}>
              <Button size="2" type="submit" onClick={() => console.log(formik.values)}>
                저장
              </Button>
              <ConfirmDialog title="저장 확인" description="저장하시겠습니까?" onConfirm={handleCreateProject} />
            </Dialog.Root>
          </Flex>
        </Box>
      </form>
    </Section>
  );
}
