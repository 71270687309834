import React from 'react';
import { Button, Dialog, IconButton, Text, Flex } from '@radix-ui/themes';
import { Cross1Icon } from '@radix-ui/react-icons';

import './style.scss';

// 계정 관리 모달 (승인 | 거절 | 정지)
export function AccountApproveDialog({ userStatus, onApproveClick, onRejectClick }) {
  const handleApprove = async () => {
    await onApproveClick('approve');
  };

  return (
    <Dialog.Content maxWidth="400px" className="basic-dialog">
      <Dialog.Close>
        <IconButton className="rt-variant-text close-btn">
          <Cross1Icon />
        </IconButton>
      </Dialog.Close>

      <Dialog.Title>계정 {userStatus === 'approve' ? '관리' : '승인'}</Dialog.Title>
      <Dialog.Description size="2" mb="var(--space-7)">
        <Text align="center">
          {userStatus === 'approve' ? '계정 상태를 변경하시겠습니까?' : '등록된 계정을 승인 하시겠습니까?'}
          <br />
        </Text>
        {userStatus === 'approve' ||
          (userStatus === 'pending' && (
            <Text align="center" className="font-info">
              {userStatus === 'approve' ? '정지된 계정은 로그인 할 수 없습니다.' : '승인하지 않은 계정은 거절 처리할 수 있습니다.'}
            </Text>
          ))}
      </Dialog.Description>

      <Flex justify="center" gap="var(--space-2)">
        {userStatus === 'pending' && (
          <Dialog.Close>
            <Dialog.Root>
              <Dialog.Trigger>
                <Button size="2" onClick={handleApprove}>
                  승인하기
                </Button>
              </Dialog.Trigger>
            </Dialog.Root>
          </Dialog.Close>
        )}
        {userStatus === 'pending' && (
          <Dialog.Close>
            <Dialog.Root>
              <Dialog.Trigger>
                <Button size="2" className="btn-error" onClick={onRejectClick}>
                  거절하기
                </Button>
              </Dialog.Trigger>
            </Dialog.Root>
          </Dialog.Close>
        )}
        {userStatus === 'approve' && (
          <Dialog.Close>
            <Dialog.Root>
              <Dialog.Trigger>
                <Button size="2" className="btn-error" onClick={onRejectClick}>
                  정지하기
                </Button>
              </Dialog.Trigger>
            </Dialog.Root>
          </Dialog.Close>
        )}

        <Dialog.Close>
          <Button size="2" variant="outline">
            취소
          </Button>
        </Dialog.Close>
      </Flex>
    </Dialog.Content>
  );
}
