import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container, Text, Section, Flex, Button, Separator } from '@radix-ui/themes';

import { TextInput, Password } from 'components/form';

import { loginUser, selectErrorInfo, selectIsLogin, selectStopReason, setError } from 'store/slices/users';
import './style.scss';

// 로그인 페이지
export default function Login() {
  // hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLogin = useSelector(selectIsLogin);
  const errorInfo = useSelector(selectErrorInfo); //
  const stopReason = useSelector(selectStopReason);

  // state
  const [id, setId] = useState(''); // 아이디
  const [password, setPassword] = useState(''); // 비밀번호

  // 에러 메시지
  const ERROR_MESSAGES = {
    invalidCredentials: (
      <>
        아이디(로그인 전용 아이디) 또는 비밀번호를 잘못 입력했습니다. <br />
        입력하신 내용을 다시 확인해주세요.
      </>
    ),
    accountSuspended: (
      <>
        입력하신 계정은 사용 불가 처리가 되었습니다.
        <br />
        {/* 사유: 보직 이동으로 인한 사용 정지 */}
        사유: {stopReason}
      </>
    )
  };

  // 아이디/비밀번호 입력 Method
  const handleIdOrPW = (type, value) => {
    dispatch(setError(null));
    type === 'id' ? setId(value) : setPassword(value);
  };

  // 로그인 API
  const handleLogin = async e => {
    e.preventDefault();

    if (!id || !password) {
      dispatch(setError('invalidCredentials'));
      return;
    }
    const result = await dispatch(loginUser({ id, password }));

    if (result.error) {
      dispatch(setError('invalidCredentials'));
    } else if (isLogin) {
      navigate('/home');
    }
  };

  // mount 시 에러 상태 초기화
  useEffect(() => {
    dispatch(setError(null));
  }, [dispatch]);

  return (
    <Section className="login-wrap">
      <Container>
        <Flex justify="center" align="start" gap="64px" height="100%">
          <img src={require('assets/images/common/img-login.svg').default} alt="NipaDreams 로그인 이미지" />
          <Flex direction="column" className="login-pannel">
            <Flex align="end" gap="var(--space-3)">
              <Box className="logo">
                <img src={require('assets/images/common/logo.svg').default} alt="NipaDreams" />
              </Box>
              <Text as="p" color="gray" className="login-text">
                통합 관리 시스템으로
                <br />
                편리하게 정보를 관리 할 수 있어요.
              </Text>
            </Flex>
            <Flex direction="column" mt="var(--space-5)" className="box-wide login-box">
              <form onSubmit={handleLogin}>
                <Flex align="center" mt="var(--space-5)" gap="8px">
                  <Text as="label" htmlFor="loginId" mt="2px" className="title3">
                    아이디
                  </Text>
                  <TextInput
                    id="loginId"
                    type="text"
                    value={id}
                    handleId={id => handleIdOrPW('id', id)}
                    placeholder="Please input your ID!"
                    autoComplete="username"
                  />
                </Flex>
                <Flex align="center" mb="var(--space-5)" gap="8px">
                  <Text as="label" htmlFor="loginPassword" mt="2px" className="title3">
                    비밀번호
                  </Text>
                  <Password
                    size="3"
                    id="loginPassword"
                    password={password}
                    handlePassword={pw => handleIdOrPW('pw', pw)}
                    placeholder="Please input your password!"
                  />
                </Flex>
                {/* 애러 처리 */}
                {errorInfo && (
                  <Flex align="start" gap="var(--space-1)" className="error-msg">
                    <Box>
                      <img src={require('assets/images/icon/icon-error.svg').default} alt="에러아이콘" />
                    </Box>
                    <Text as="span" className="body3">
                      {ERROR_MESSAGES[errorInfo]}
                    </Text>
                  </Flex>
                )}
                <Button mt={errorInfo ? 'var(--space-5)' : 'var(--space-16)'} className="fluid height-lg" type="submit">
                  <Text className="sub-title1">로그인</Text>
                </Button>
              </form>

              <Flex align="center" justify="end" gap="var(--space-3)" mt="var(--space-6)">
                <Button variant="ghost" className="sub-title2">
                  <Text
                    color="gray"
                    className="sub-title2"
                    onClick={() => {
                      navigate('/findId');
                    }}
                  >
                    아이디 찾기
                  </Text>
                </Button>
                <Separator orientation="vertical" size="1" />
                <Button
                  variant="ghost"
                  className="sub-title2"
                  onClick={() => {
                    navigate('/findPassword');
                  }}
                >
                  <Text color="gray" className="sub-title2">
                    비밀번호 찾기
                  </Text>
                </Button>
                <Separator orientation="vertical" size="1" />
                <Button
                  variant="ghost"
                  className="sub-title2"
                  onClick={() => {
                    navigate('/membership');
                  }}
                >
                  <Text color="gray" className="sub-title2">
                    회원가입
                  </Text>
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Text as="p" className="body3 copyright">
          Copyright © 2024 RSW All Right Reserved.
        </Text>
      </Container>
    </Section>
  );
}
