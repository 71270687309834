import React from 'react';
import { Button, Dialog, IconButton, Flex, VisuallyHidden, Box, Text } from '@radix-ui/themes';
import { Cross1Icon } from '@radix-ui/react-icons';
import { useFormik } from 'formik';

import { validationSchemaReject } from 'util/validationSchemas';
import { Textarea } from '../form';

import './style.scss';

//  계정 정지 혹은 승인 거절 사유 모달
export function AccountReasonDialog({ userStatus, onClick }) {
  const formik = useFormik({
    initialValues: {
      reason: ''
    },
    validationSchema: validationSchemaReject,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async values => {
      if (userStatus === 'pending') {
        await onClick('reject', values.reason);
      } else if (userStatus === 'approve') {
        await onClick('unused', values.reason);
      }
    }
  });

  return (
    <Dialog.Content maxWidth="400px" className="basic-dialog">
      <Dialog.Close>
        <IconButton className="rt-variant-text close-btn">
          <Cross1Icon />
        </IconButton>
      </Dialog.Close>

      <Dialog.Title>{userStatus === 'pending' ? '승인 거절' : '계정 정지'}</Dialog.Title>
      <VisuallyHidden>
        <Dialog.Description />
      </VisuallyHidden>
      <form onSubmit={formik.handleSubmit}>
        <Box size="2" mt="var(--space-6)" mb="var(--space-7)">
          <>
            <Textarea
              required
              maxLength="300"
              placeholder="사유를 입력하여 주세요."
              name="reason"
              value={formik.values.reason}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched?.reason && formik.errors?.reason && <Text color="red">{formik.errors?.reason}</Text>}
          </>
        </Box>

        <Flex gap="var(--space-2)" justify="center">
          <Button size="2" className="btn-lg" type="submit">
            {userStatus === 'pending' ? '거절하기' : '정지하기'}
          </Button>
          <Dialog.Close>
            <Button size="2" variant="outline">
              취소
            </Button>
          </Dialog.Close>
        </Flex>
      </form>
    </Dialog.Content>
  );
}
