import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Checkbox, Box, Button, Flex, Text, Table, TextField, Dialog } from '@radix-ui/themes';
import { useFormik } from 'formik';

import { DatePickers, DeleteButton, FileUpload } from 'components/form';
import { ConfirmDialog } from 'components/dialog';
import Editor from 'components/editor';

import { instance } from 'api/axios.instance';
import { selectRegion } from 'store/slices/users';
import { regions } from 'app/constants';
import { validationSchemaNotice } from 'util/validationSchemas';

import './boardWrite.scss';
import { format } from 'date-fns';

// 게시판 관리 > 등록/수정 테이블 컴포넌트 (웹 마스터 관리자 전용)
export default function BoardWrite({ boardType }) {
  // hooks
  const { id } = useParams();
  const navigate = useNavigate();
  const userRegion = useSelector(selectRegion);
  const region = regions[userRegion] || 'ALL';

  // state
  const [isEditMode, setIsEditMode] = useState(false); // 수정 모드
  const [isOpenSaveDialog, setIsOpenSaveDialog] = useState(false); // 저장 모달
  const [deleteFileIds, setDeleteFileIds] = useState([]); // 삭제할 파일 아이디 목록
  const [formValues, setFormValues] = useState({
    post: {
      boardType, // 게시물 타입
      region, // 지역
      title: '', // 게시물 제목
      content: '', // 게시물 내용
      link: '', // 관련 링크
      videoLink: '', // 동영상 링크
      startDate: '', // 시작일
      endDate: '' // 종료일
    },
    files: [], // 파일 첨부 목록
    thumbnails: [] // 썸네일 등록 목록
  });

  // form 유효성 검사
  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validationSchemaNotice,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: values => {
      setFormValues(values);
      setIsOpenSaveDialog(true);
    }
  });

  // FormData Method
  const convertToFormData = () => {
    const formData = new FormData();
    formData.append('body', JSON.stringify({ ...formValues.post, deleteFileIds }));
    formValues.files.forEach(file => {
      formData.append('files', file);
    });
    formValues.thumbnails.forEach(file => {
      formData.append('thumbnails', file);
    });
    return formData;
  };

  // 파일 첨부 업로드 Method
  const handleFileChange = newFiles => {
    const updatedFiles = [...formik.values.files, ...newFiles];
    formik.setFieldValue('files', updatedFiles);
  };

  // 파일 삭제 Method
  const handleFileDelete = index => {
    const updatedFiles = [...formik.values.files];
    const fileNo = updatedFiles[index]?.fileNo;
    if (fileNo) {
      setDeleteFileIds(prevItems => [...prevItems, fileNo]);
    }
    updatedFiles.splice(index, 1);
    formik.setFieldValue('files', updatedFiles);
  };

  // 게시물 상세 조회 API
  const fetchPostDetail = useCallback(async () => {
    const response = await instance(`/post/${id}`, {
      method: 'GET'
    });
    setFormValues(response.data.data);
  }, [id]);

  // 게시물 등록/수정 API
  const fetchPostCreateOrEdit = async () => {
    const type = isEditMode ? 'edit' : 'create';

    const response = await instance(`/post/${type}`, {
      method: 'POST',
      data: convertToFormData(),
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    if (response.data.status === 201) {
      navigate(`/${boardType}`);
    }
  };

  // mount 시 id 여부에 따라 수정 모드 분기 처리
  useEffect(() => {
    if (id) {
      setIsEditMode(true);
      fetchPostDetail();
    }
  }, [id, fetchPostDetail]);

  return (
    <form onSubmit={formik.handleSubmit}>
      {/* 글 제목 */}
      <Box className="box box-white">
        <Table.Root className="table-write">
          <Table.Body>
            <Table.Row align="center">
              <Table.ColumnHeaderCell colSpan={2}>
                <Text className="required-start">글 제목</Text>
              </Table.ColumnHeaderCell>
              <Table.Cell colSpan={10}>
                <TextField.Root
                  required
                  placeholder="제목을 입력하세요."
                  name="post.title"
                  value={formik.values.post.title || ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched?.post?.title && formik.errors?.post?.title && (
                  <Text as="p" mt="var(--space-1)" className="font-error body3">
                    {formik.errors?.post?.title}
                  </Text>
                )}
              </Table.Cell>
            </Table.Row>
            {boardType === 'notice' && (
              <Table.Row align="center">
                <Table.ColumnHeaderCell colSpan={2}>
                  <Text>상단 고정</Text>
                </Table.ColumnHeaderCell>
                <Table.Cell colSpan={10}>
                  <Flex align="center" gap="var(--space-1)">
                    <Checkbox
                      checked={formik.values.post.isFixed}
                      onCheckedChange={checked => formik.setFieldValue('post.isFixed', checked)}
                    />
                    <Text>고정</Text>
                  </Flex>
                </Table.Cell>
              </Table.Row>
            )}
            {/* 홍보자료일 경우 */}
            {['promotion'].includes(boardType) && (
              <Table.Row align="center">
                <Table.ColumnHeaderCell colSpan={2}>
                  <Text className="required-start">시작일</Text>
                </Table.ColumnHeaderCell>
                <Table.Cell colSpan={4}>
                  <DatePickers
                    id="startDate"
                    name="post.startDate"
                    value={formik.values.post.startDate || ''}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    min={format(new Date(), 'yyyy-MM-dd')}
                    required={false}
                  />
                </Table.Cell>
                <Table.ColumnHeaderCell colSpan={2}>
                  <Text className="required-start">종료일</Text>
                </Table.ColumnHeaderCell>
                <Table.Cell colSpan={4}>
                  <DatePickers
                    id="endDate"
                    name="post.endDate"
                    value={formik.values.post.endDate || ''}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    min={formik.values.post.startDate || format(new Date(), 'yyyy-MM-dd')}
                    required={false}
                  />
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table.Root>
      </Box>

      {/*  에디터 영역 */}
      <Box className="box box-white">
        <Editor
          board={boardType}
          editorData={formik.values.post.content || ''}
          setEditorData={data => formik.setFieldValue('post.content', data)}
        />
      </Box>

      {['notice', 'promotion'].includes(boardType) && (
        <Box className="box box-white">
          <Table.Root className="table-write">
            <Table.Body>
              {/* 동영상 링크 */}
              <Table.Row align="center">
                <Table.ColumnHeaderCell colSpan={2}>
                  <Text className="">동영상 링크</Text>
                </Table.ColumnHeaderCell>
                <Table.Cell colSpan={10} className="url-link">
                  <TextField.Root
                    placeholder="동영상 링크를 입력하세요."
                    title="동영상 url 입력 창"
                    name="post.videoLink"
                    value={formik.values.post.videoLink || ''}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    size="2"
                  />
                </Table.Cell>
              </Table.Row>

              {/* 관련 링크 */}
              <Table.Row align="center">
                <Table.ColumnHeaderCell colSpan={2}>
                  <Text className="">관련 링크</Text>
                </Table.ColumnHeaderCell>
                <Table.Cell colSpan={10} className="url-link">
                  <TextField.Root
                    size="2"
                    title="관련 링크 url 입력 창"
                    placeholder="관련 링크를 입력하세요."
                    name="post.link"
                    value={formik.values.post.link || ''}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Table.Cell>
              </Table.Row>

              {/* 파일 첨부 */}
              <Table.Row align="center">
                <Table.ColumnHeaderCell colSpan={2}>
                  <Text className="">파일 첨부</Text>
                </Table.ColumnHeaderCell>
                <Table.Cell colSpan={10}>
                  <Flex direction="column" align="start" px="var(--space-1)">
                    <FileUpload label="파일선택" id="file-upload" message="개당 50MB" onFileChange={handleFileChange} />
                    {formik.values.files.length > 0 &&
                      formik.values.files.map((file, index) => (
                        <Box key={index} className="file-item">
                          <Text as="p" color="gray">
                            {file.originalName || file.name}
                          </Text>
                          <DeleteButton onDelete={() => handleFileDelete(index)} />
                        </Box>
                      ))}
                  </Flex>
                </Table.Cell>
              </Table.Row>

              {/* 썸네일 등록 */}
              {boardType === 'promotion' && (
                <Table.Row align="center">
                  <Table.ColumnHeaderCell colSpan={2}>
                    <Text className="">썸네일 등록</Text>
                  </Table.ColumnHeaderCell>
                  <Table.Cell colSpan={10}>
                    <Flex align="center" gap="var(--space-2)" px="var(--space-1)">
                      <FileUpload
                        label="파일선택"
                        id="thumbnail-upload"
                        accept="image/*"
                        message="IMG 파일, 개당 50MB"
                        multiple={false}
                        onFileChange={thumbnail => formik.setFieldValue('thumbnails', [thumbnail])}
                      />
                    </Flex>
                    {formik.values.thumbnails.length > 0 && (
                      <Box mt="2">
                        <img
                          src={formik.values.thumbnails[0].fileUrl || URL.createObjectURL(formik.values.thumbnails[0])}
                          alt="썸네일 미리보기"
                          style={{ width: '150px', height: '212px' }}
                        />
                        <Box className="file-item" mt="2">
                          <Text as="p" color="gray">
                            {formik.values.thumbnails[0].originalName || formik.values.thumbnails[0].name}
                          </Text>
                          <DeleteButton onDelete={() => formik.setFieldValue('thumbnails', [])} />
                        </Box>
                      </Box>
                    )}
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table.Root>
        </Box>
      )}

      {/* 테이블 액션 */}
      <Box>
        <Flex justify="end" gap="2">
          <Dialog.Root>
            <Dialog.Trigger>
              <Button size="2" variant="outline" type="button">
                취소
              </Button>
            </Dialog.Trigger>
            <ConfirmDialog
              title="저장 취소"
              description="작성중인 내용을 저장하지 않고 나가시겠습니까?"
              onConfirm={() => navigate(`/${boardType}`)}
            />
          </Dialog.Root>
          <Dialog.Root open={isOpenSaveDialog} onOpenChange={setIsOpenSaveDialog}>
            <Button size="2" type="submit">
              {isEditMode ? '수정' : '저장'}
            </Button>
            <ConfirmDialog title="저장 확인" description="저장하시겠습니까?" onConfirm={fetchPostCreateOrEdit} />
          </Dialog.Root>
        </Flex>
      </Box>
    </form>
  );
}
