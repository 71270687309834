import React from 'react';
import { Flex } from '@radix-ui/themes';
import './style.scss';

// 메인 페이지
export default function Main() {
  return (
    <Flex align="center" justify="center" className="main-img-box">
      <img src={require('assets/images/common/img-login.svg').default} alt="NipaDreams 로그인 이미지" />
    </Flex>
  );
}
