import { Box, Button, Dialog, Flex } from '@radix-ui/themes';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { ConfirmDialog } from 'components/dialog';
import { selectType } from 'store/slices/users';
import { useNavigate } from 'react-router-dom';
import { instance } from 'api/axios.instance';
import { TableContext } from './TableWrapper';

// 테이블 하단 액션 컴포넌트 (삭제, 프로젝트 등록, 글쓰기 등)
export default function TableAction() {
  const navigate = useNavigate();
  const { tableBody, boardType, selectedPosts, refetch } = useContext(TableContext);
  const type = useSelector(selectType);

  // 게시물 삭제
  const handleDeletePost = async () => {
    if (selectedPosts === '') return;
    let response;
    const selectedPost = tableBody.list[selectedPosts];
    if (boardType === 'agenda') {
      response = await instance('/admin/agenda/edit', {
        method: 'POST',
        data: {
          agendaCode: selectedPost.agendaCode,
          isDeleted: true
        }
      });
      if (response.data.status === 201) {
        refetch();
      }
    } else if (['notice', 'promotion', 'question'].includes(boardType)) {
      response = await instance(`/post/${selectedPost.postNo}`, {
        method: 'DELETE'
      });
      if (response.data.status === 200) {
        refetch();
      }
    }
  };

  return (
    <Box>
      {type === 'user' && (
        <Flex justify="end">
          <Button className="btn-xlg" onClick={() => navigate(`/${boardType}/write`)}>
            {boardType === 'projectRequest' ? '프로젝트 등록' : '문의하기'}
          </Button>
        </Flex>
      )}

      {['agenda', 'realSurvey', 'localStatus', 'notice', 'promotion'].includes(boardType) && (
        <Flex justify="between" gap="2">
          {tableBody?.pagination?.totalCount > 0 && (
            <Dialog.Root>
              <Dialog.Trigger>
                <Button variant="outline">삭제</Button>
              </Dialog.Trigger>
              <ConfirmDialog
                title="선택한 항목 제거"
                description={selectedPosts === '' ? '선택한 항목이 없습니다.' : '선택한 항목을 제거 하시겠습니까?'}
                onConfirm={handleDeletePost}
                showCancel={selectedPosts !== ''}
              />
            </Dialog.Root>
          )}

          <Flex justify="end" gap="2">
            <Button className="btn-lg" onClick={() => navigate(`/${boardType}/write`)}>
              글쓰기
            </Button>
          </Flex>
        </Flex>
      )}
    </Box>
  );
}
