import React, { useEffect, useState } from 'react';
import { Box, Flex, IconButton, Button } from '@radix-ui/themes';
import { ChevronLeftIcon, ChevronRightIcon, DoubleArrowLeftIcon, DoubleArrowRightIcon } from '@radix-ui/react-icons';

import './pagination.scss';
import { useSearchParams } from 'react-router-dom';

// 테이블 페이지네이션
export default function Pagination({ totalPage, handlePageChange }) {
  // hooks
  const [searchParams] = useSearchParams();
  const [pageNumbers, setPageNumbers] = useState([]);
  const currentPage = Number(searchParams.get('page')) || 1;

  // state
  const pageLimit = 5; // 한 번에 보여줄 페이지 수
  const startPage = Math.max(1, currentPage - Math.floor(pageLimit / 2));
  const endPage = Math.min(totalPage, startPage + pageLimit - 1);

  useEffect(() => {
    // 페이지 번호 배열 계산
    const numbers = [];
    for (let i = startPage; i <= endPage; i++) {
      numbers.push(i);
    }

    setPageNumbers(numbers);
  }, [endPage, startPage]);

  return (
    <Box className="pagination">
      <Flex>
        <Flex className="rt-r-gap-1">
          <IconButton title="처음으로" variant="solid" onClick={() => handlePageChange(1)} disabled={currentPage === 1}>
            <DoubleArrowLeftIcon width={18} height={18} />
          </IconButton>
          <IconButton title="이전" variant="solid" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
            <ChevronLeftIcon width={18} height={18} />
          </IconButton>
        </Flex>
        <Flex className="pages">
          {startPage > 1 && (
            <Button size="1" className="rt-variant-text btn-sm" disabled>
              ...
            </Button>
          )}
          {pageNumbers.map(pageNum => (
            <Button
              key={pageNum}
              size="1"
              className={`rt-variant-text btn-sm ${currentPage === pageNum ? 'currentPage' : ''}`}
              onClick={() => handlePageChange(pageNum)}
            >
              {pageNum}
            </Button>
          ))}
          {endPage < totalPage && (
            <Button size="1" className="rt-variant-text btn-sm" disabled>
              ...
            </Button>
          )}
        </Flex>
        <Flex>
          <IconButton title="다음" variant="solid" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPage}>
            <ChevronRightIcon width={18} height={18} />
          </IconButton>
          <IconButton title="마지막으로" variant="solid" onClick={() => handlePageChange(totalPage)} disabled={currentPage === totalPage}>
            <DoubleArrowRightIcon width={18} height={18} />
          </IconButton>
        </Flex>
      </Flex>
    </Box>
  );
}
