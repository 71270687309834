import React from 'react';
import { Section } from '@radix-ui/themes';

import PageTitle from 'components/layout/PageTitle';
import BoardDetail from 'components/board/BoardDetail';

// 문의사항 > 상세 (웹 마스터 관리자 전용)
export default function QuestionDetail() {
  const boardType = 'question';

  return (
    <Section>
      <PageTitle title="문의사항" />
      <BoardDetail boardType={boardType} />
    </Section>
  );
}
