// validationSchemas.js
import * as Yup from 'yup';

export const validationSchemaTitle = Yup.object().shape({
  title: Yup.string().required('프로젝트명은 필수 입력 항목입니다.')
});

export const validationSchemaProject = ({ personalInfoIndex, organizationIndex }) =>
  Yup.object().shape({
    title: Yup.string().required('프로젝트명은 필수 입력 항목입니다.'),
    description: Yup.string().required('설명은 필수 입력 항목입니다.'),
    source: Yup.string().required('출처는 필수 입력 항목입니다.'),
    agendaData: Yup.array().of(
      Yup.object().shape({
        templateData: Yup.object().shape({
          name: Yup.string().test('check-name-for-specific-index', '이름은 필수입니다.', function (value) {
            if (personalInfoIndex === this.options.index) {
              return !!value;
            }
            return true;
          }),
          position: Yup.string().test('check-position-for-specific-index', '직위는 필수입니다.', function (value) {
            if (personalInfoIndex === this.options.index) {
              return !!value;
            }
            return true;
          }),
          phone: Yup.string()
            .matches(/^\d{3}-\d{3,4}-\d{4}$/, '전화번호 형식이 올바르지 않습니다.')
            .test('check-phone-for-specific-index', '전화번호는 필수입니다.', function (value) {
              if (personalInfoIndex === this.options.index) {
                return !!value;
              }
              return true;
            }),
          email: Yup.string()
            .email('올바른 이메일 주소를 입력하세요.')
            .test('check-email-for-specific-index', '이메일은 필수입니다.', function (value) {
              if (personalInfoIndex === this.options.index) {
                return !!value;
              }
              return true;
            }),
          list: Yup.array().of(
            Yup.object().shape({
              participateName: Yup.string().test('check-participate-name-for-specific-index', '기관명은 필수입니다.', function (value) {
                const { path } = this;
                const match = path.match(/agendaData\[(\d+)\]/);
                const listIndex = match ? parseInt(match[1], 10) : -1;

                if (organizationIndex === listIndex) {
                  console.log(value);
                  return !!value;
                }
                return true;
              }),
              fileUrl: Yup.string().test('check-participate-name-for-specific-index', '파일 첨부는 필수입니다.', function (value) {
                const { path } = this;
                const match = path.match(/agendaData\[(\d+)\]/);
                const listIndex = match ? parseInt(match[1], 10) : -1;

                if (organizationIndex === listIndex) {
                  return !!value;
                }
                return true;
              })
            })
          )
        })
      })
    )
  });

export const validationSchemaAgendaRequest = ({ personalInfoIndex, organizationIndex }) =>
  Yup.object().shape({
    title: Yup.string().required('프로젝트명은 필수 입력 항목입니다.'),
    agendaData: Yup.array().of(
      Yup.object().shape({
        templateData: Yup.object().shape({
          name: Yup.string().test('check-name-for-specific-index', '이름은 필수입니다.', function (value) {
            if (personalInfoIndex === this.options.index) {
              return !!value;
            }
            return true;
          }),
          position: Yup.string().test('check-position-for-specific-index', '직위는 필수입니다.', function (value) {
            if (personalInfoIndex === this.options.index) {
              return !!value;
            }
            return true;
          }),
          phone: Yup.string()
            .matches(/^\d{3}-\d{3,4}-\d{4}$/, '전화번호 형식이 올바르지 않습니다.')
            .test('check-phone-for-specific-index', '전화번호는 필수입니다.', function (value) {
              if (personalInfoIndex === this.options.index) {
                return !!value;
              }
              return true;
            }),
          email: Yup.string()
            .email('올바른 이메일 주소를 입력하세요.')
            .test('check-email-for-specific-index', '이메일은 필수입니다.', function (value) {
              if (personalInfoIndex === this.options.index) {
                return !!value;
              }
              return true;
            }),
          list: Yup.array().of(
            Yup.object().shape({
              participateName: Yup.string().test('check-participate-name-for-specific-index', '기관명은 필수입니다.', function (value) {
                const { path } = this;
                const match = path.match(/agendaData\[(\d+)\]/);
                const listIndex = match ? parseInt(match[1], 10) : -1;

                if (organizationIndex === listIndex) {
                  return !!value;
                }
                return true;
              }),
              fileUrl: Yup.string().test('check-fileUrl-name-for-specific-index', '파일 첨부는 필수입니다.', function (value) {
                const { path } = this;
                const match = path.match(/agendaData\[(\d+)\]/);
                const listIndex = match ? parseInt(match[1], 10) : -1;

                if (organizationIndex === listIndex) {
                  return !!value;
                }
                return true;
              })
            })
          )
        })
      })
    )
  });

export const validationSchemaNotice = Yup.object().shape({
  post: Yup.object().shape({
    title: Yup.string().required('글 제목은 필수 입력 항목입니다.')
  })
});

// export const validationSchemaPromotion = Yup.object().shape({
//     post: Yup.object().shape({
//         title: Yup.string().required('글 제목은 필수 입력 항목입니다.'),
//         startDate: Yup.string().required('시작일은 필수 입력 항목입니다.'),
//         endDate: Yup.string().required('종료일은 필수 입력 항목입니다.')
//     })
// });

export const validationSchemaQuestion = Yup.object().shape({
  title: Yup.string().required('글 제목은 필수 입력 항목입니다.'),
  content: Yup.string().required('문의 내용을 입력해주세요.')
});

export const validationRegister = Yup.object().shape({
  region: Yup.string().required('신청 지역은 필수입니다.'),
  email: Yup.string().email('유효한 이메일을 입력하세요.').required('필수 입력 사항입니다.')
});

export const validationTerms = validationRegister.concat(
  Yup.object().shape({
    termsOfService: Yup.boolean().oneOf([true], '[필수] 이용약관에 동의해 주세요.'),
    privacyPolicy: Yup.boolean().oneOf([true], '[필수] 개인정보 수집 및 이용에 동의해 주세요.')
  })
);

export const validationAuthCode = Yup.string().required('인증 번호를 입력하세요.').length(6, '인증 번호는 6자리여야 합니다.');

export const validationEmail = Yup.object({
  email: Yup.string().email('유효하지 않은 이메일 형식입니다.').required('이메일을 입력해주세요.')
});

export const validationSchemaAgenda = Yup.object().shape({
  agendaName: Yup.string().required('아젠다 명은 필수 입력 항목입니다.'),
  startDate: Yup.string().required('사용 기간은 필수 입력 항목입니다.'),
  endDate: Yup.string().required('사용 기간은 필수 입력 항목입니다.')
});

export const validationSchemaStateChange = Yup.object().shape({
  reason: Yup.string().required('수정 요청 사유는 필수 입력 항목입니다.')
});

export const validationSchemaReject = Yup.object().shape({
  reason: Yup.string().required('사유는 필수 입력 항목입니다.')
});

export const validationSchemaPassword = Yup.object().shape({
  newPassword: Yup.string()
    .matches(/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,}$/, '영문, 숫자 조합 6자 이상 입력해주세요.')
    .required('필수 입력 항목입니다.'),
  newPasswordConfirm: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], '새로운 비밀번호가 일치하지 않습니다.')
    .required('필수 입력 항목입니다.')
});
