import React from 'react';
import { Section } from '@radix-ui/themes';

import PageTitle from 'components/layout/PageTitle';
import BoardDetail from 'components/board/BoardDetail';

// 게시판 관리 > 공지사항 관리 > 상세 (웹 마스터 관리자 전용)
export default function NoticeDetail() {
  const boardType = 'notice';

  return (
    <Section>
      <PageTitle title="공지사항" />
      <BoardDetail boardType={boardType} />
    </Section>
  );
}
