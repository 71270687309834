import React from 'react';
import { Button, Flex, Dialog, IconButton, VisuallyHidden } from '@radix-ui/themes';
import { Cross1Icon } from '@radix-ui/react-icons';

import './style.scss';

// 공통 모달
export function ConfirmDialog({
  title,
  description,
  description2,
  onConfirm = null,
  showCancel = true,
  showTitle = false,
  buttonName = '확인'
}) {
  return (
    <Dialog.Content maxWidth="400px" className="basic-dialog">
      <Dialog.Close>
        <IconButton className="rt-variant-text close-btn">
          <Cross1Icon />
        </IconButton>
      </Dialog.Close>
      {showTitle ? (
        <Dialog.Title>{title}</Dialog.Title>
      ) : (
        <VisuallyHidden>
          <Dialog.Title>{title}</Dialog.Title>
        </VisuallyHidden>
      )}

      <Dialog.Description size="2" mb="var(--space-7)">
        {description}
        {/* 임시 - decsription2 두 줄 처리  */}
        {description2 && (
          <>
            <br />
            {description2}
          </>
        )}
      </Dialog.Description>
      <Flex gap="var(--space-2)" justify="center">
        <Dialog.Close>
          <Button onClick={onConfirm} type="button" size="2">
            {buttonName}
          </Button>
        </Dialog.Close>
        {showCancel && (
          <Dialog.Close>
            <Button type="button" size="2" variant="outline">
              취소
            </Button>
          </Dialog.Close>
        )}
      </Flex>
    </Dialog.Content>
  );
}
